import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import NotificationInterface from '@/interfaces/NotificationInterface';

@Module({
    namespaced: true,
    name: 'notificationModule',
})
class NotificationModule extends VuexModule {
    public snackBar: NotificationInterface = {} as NotificationInterface;
    private alert: NotificationInterface = {} as NotificationInterface;

    @Mutation
    public setSnackBarNotification(payload: NotificationInterface): void {
        this.snackBar = payload;
    }

    @Mutation
    public setAlertNotification(payload: NotificationInterface): void {
        this.alert = payload;
    }

    @Mutation
    public clearAlertNotification(): void {
        this.alert = {} as NotificationInterface;
    }

    public get isAlertNotified(): boolean {
        return Boolean(this.alertNotification.msg);
    }

    public get getSnackBarNotification(): NotificationInterface {
        return this.snackBar;
    }

    public get alertNotification(): NotificationInterface {
        return this.alert;
    }
}

export default NotificationModule;
