export default class ValidationUtil {
    public static processSymfonyFormErrors(errors: any): string {
        let messages = '';

        if (errors) {
            /* eslint-disable-next-line */
            for (const [key, value] of Object.entries(errors)) {
                messages += `${value} </br>`;
            }
        }

        return messages;
    }
}
