import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';
import QueryParamsUtil from '@/utils/QueryParamsUtil';
import AuthenticatedInterface from '@/interfaces/AuthenticatedInterface';
import HttpConfigInterface from '@/interfaces/HttpConfigInterface';

export default class BaseApiClient {
    protected backend: string = store.getters['siteConfigModule/getBackend'];
    protected authenticatedUser: AuthenticatedInterface = store.getters['authModule/authenticatedUser'];
    protected type: string = '';


    public getItems(page: number = 1, perPage: number, exportCsv: number = 0, params: any = {}, config: HttpConfigInterface = {}): AxiosPromise {
        let endpoint = `/web-api/${this.type}?page=${page}&length=${perPage}&export_csv=${exportCsv}`;

        if (Object.keys(params).length) {
            endpoint += `&${QueryParamsUtil.objectToQueryParamsString(params)}`;
        }

        return HttpAdapter.get(`${this.backend}${endpoint}`, config);
    }
}
