import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import LocalStorageUtil from '@/utils/LocalStorageUtil';

@Module({
    namespaced: true,
    name: 'themeModule',
})
class ThemeModule extends VuexModule {
    public isMenuMinimized: boolean = LocalStorageUtil.isMenuMinimized();
    public isShowTooltips: boolean = LocalStorageUtil.isShowTooltips();
    public isDarkTheme: boolean = LocalStorageUtil.isDarkTheme();
    public isHideIpAddresses: boolean = LocalStorageUtil.isHideIpAddresses();

    @Mutation
    public changeMinimized(): void {
        this.isMenuMinimized = !this.isMenuMinimized;

        localStorage.setItem('isMenuMinimized', `${this.isMenuMinimized}`);
    }

    @Mutation
    public changeTooltips(): void {
        this.isShowTooltips = !this.isShowTooltips;

        localStorage.setItem('isShowTooltips', `${this.isShowTooltips}`);
    }

    @Mutation
    public changeTheme(): void {
        this.isDarkTheme = !this.isDarkTheme;

        localStorage.setItem('isDarkTheme', `${this.isDarkTheme}`);
    }

    @Mutation
    public changeHideIpAddresses(): void {
        this.isHideIpAddresses = !this.isHideIpAddresses;

        localStorage.setItem('isHideIpAddresses', `${this.isHideIpAddresses}`);
    }

    public get showTooltips(): boolean {
        return this.isShowTooltips;
    }

    public get menuMinimized(): boolean {
        return this.isMenuMinimized;
    }

    public get darkTheme(): boolean {
        return this.isDarkTheme;
    }

    public get showIpAddresses(): boolean {
        return !this.isHideIpAddresses;
    }
}

export default ThemeModule;
