
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import CommonRules from '@/rules/CommonRules';
import NotificationInterface from '@/interfaces/NotificationInterface';
import FilesUploadApi from '@/services/api/FilesUploadApi';

const notification = namespace('notificationModule');

@Component
export default class StorNextLicenseReportDialog extends Vue {
    @notification.Mutation
    public setSnackBarNotification!: (notificationData: NotificationInterface) => void;

    public dialog: boolean = false;
    public valid: boolean = false;
    public rules = { ...CommonRules };
    public isSending: boolean = false;
    public licenseReportFile: any = null;

    public async setShow(): Promise<void> {
        this.dialog = true;
    }

    public close(): void {
        this.dialog = false;
        this.licenseReportFile = null;
        this.isSending = false;

        (this.$refs.form as any).resetValidation();
    }

    public submit(): void {
        this.isSending = true;

        const formData: FormData = new FormData();
        formData.append('file', this.licenseReportFile);

        (new FilesUploadApi()).uploadLicenseReport(
            formData
        ).then(() => {
            this.setUploadCompleteNotification();

            this.close();
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.isSending = false;
        });
    }

    public setUploadCompleteNotification(): void {
        this.setSnackBarNotification({
            msg: 'File uploaded successfully',
            type: 'success'
        });
    }
}
