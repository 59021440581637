export default class HealthUtil {
    public static getHealthColor(score: number): string {
        if (score === -1) return '#6A7B84';

        if (score < 60) return '#d12021';

        if (score < 70) return '#F47F16';

        if (score < 80) return '#E68437';

        return '#4CAF50';
    }
}
