import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import CommonRules from '@/rules/CommonRules';
import SolutionRules from '@/rules/SolutionRules';
import NotificationInterface from '@/interfaces/NotificationInterface';

const auth = namespace('authModule');
const notification = namespace('notificationModule');
const theme = namespace('themeModule');

@Component
export default class BaseCustomerDialog extends Vue {
    public dialog: boolean = false;
    public rules = { ...CommonRules, ...SolutionRules };
    public valid: boolean = true;
    @auth.Getter
    public canAddCustomer!: boolean;
    @auth.Getter
    public canSeeCustomers!: boolean;
    @auth.Getter
    public canSeeCustomerTypes!: boolean;
    @notification.Mutation
    public setSnackBarNotification!: (notificationData: NotificationInterface) => void;
    @theme.Getter
    public showTooltips!: boolean;
}
