import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import BaseApiClient from '@/services/api/BaseApiClient';

export default class MessagesApi extends BaseApiClient {
    protected type: string = 'messages';

    public getNotifications(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/messages/toggle-notifications`);
    }

    public toggleNotifications(switch_on: boolean = false): AxiosPromise {
        return HttpAdapter.post(
            `${this.backend}/web-api/messages/toggle-notifications`,
            {
                switch_on
            }
        );
    }

    public getAllMessages(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/messages`);
    }

    public getMessagesTypes(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/messages/types`);
    }

    public actionMessage(id: number, action: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/messages/${id}/${action}`);
    }

    public dismissAllMessages(): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/messages/dismiss-all`);
    }
}
