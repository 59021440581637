
import { namespace } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ClipboardCopy from '@/components/common/ClipboardCopy.vue';
import ProductKeyApi from '@/services/api/ProductKeyApi';
import ProfileInterface from '@/interfaces/ProfileInterface';
import CommonRules from '@/rules/CommonRules';
import NotificationInterface from '@/interfaces/NotificationInterface';
import NodeItemInterface from '@/interfaces/Nodes/NodeItemInterface';

const auth = namespace('authModule');
const notification = namespace('notificationModule');
const theme = namespace('themeModule');

export interface productKeysPayloadData {
    license_id: string, // minimum 3 characters
    node1: string, // minimum 2 characters
    node2?: string | null,
    firstName: string, // minimum 2 characters
    lastName: string, // minimum 2 characters
    email: string, // minimum 3 characters, valid email format
    company: string, // minimum 2 characters
    address1: string, // minimum 2 characters
    address2?: string | null,
    address3?: string | null,
    address4?: string | null,
    city: string, // minimum 2 characters
    state?: string | null,
    postalCode: string, // minimum 2 characters
    country: string, // minimum 2 characters
    phone?: string | null,
    jobTitle?: string | null,
    resellerName?: string | null,
}

const DEFAULT_BODY = {
    license_id: '',
    node1: '',
    node2: null,
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    address1: '',
    address2: null,
    address3: null,
    address4: null,
    city: '',
    state: null,
    postalCode: '',
    country: '',
    phone: null,
    jobTitle: null,
    resellerName: null,
};

@Component({
    components: {
        ClipboardCopy,
    },
})
export default class StorNextKeyDialog extends Vue {
    @auth.Getter
    public user!: ProfileInterface;
    @auth.Action
    public getCurrentUser!: () => Promise<ProfileInterface>;
    @notification.Mutation
    public setSnackBarNotification!: (notificationData: NotificationInterface) => void;
    @theme.Getter
    public showTooltips!: boolean;

    @Prop({ required: false })
    public node!: NodeItemInterface;

    public dialog: boolean = false;
    public valid: boolean = false;
    public rules = { ...CommonRules };
    public isSending: boolean = false;
    public isLoading: boolean = false;
    public productBody: productKeysPayloadData = { ...DEFAULT_BODY };
    public productKeys: string[] = [];

    public async setShow(): Promise<void> {
        this.$emit('closeMenu');
        this.dialog = true;
        this.isLoading = true;

        try {
            this.productKeys = [];
            this.productBody = { ...DEFAULT_BODY };

            if (this.node?.id) {
                const response = await (new ProductKeyApi()).getProductKeyInfo(this.node.id);

                const data = response.data.result;

                this.productBody.license_id = data.license_id || '';
                this.productBody.address1 = data.address1 || '';
                this.productBody.city = data.city || '';
                this.productBody.company = data.company || '';
                this.productBody.country = data.country || '';
                this.productBody.email = data.email || '';
                this.productBody.firstName = data.firstName || '';
                this.productBody.lastName = data.lastName || '';
                this.productBody.jobTitle = data.jobTitle || '';
                this.productBody.postalCode = data.postalCode || '';
                this.productBody.state = data.state || '';
            } else {
                await this.getCurrentUser();

                this.productBody.email = this.user.email || '';
                this.productBody.firstName = this.user.firstName || '';
                this.productBody.lastName = this.user.lastName || '';
                this.productBody.company = this.user.company || '';
                this.productBody.country = this.user.country || '';
                this.productBody.state = this.user.stateText || '';
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;

            if (this.$refs.form as any) {
                (this.$refs.form as any).resetValidation();
            }
        }
    }

    public close(): void {
        this.dialog = false;
        this.productKeys = [];
        this.productBody = { ...DEFAULT_BODY };

        this.isSending = false;
        this.isLoading = false;

        (this.$refs.form as any).resetValidation();
    }

    public async retrieveProductKey(): Promise<void> {
        this.isSending = true;
        this.productKeys = [];

        try {
            const response = await (new ProductKeyApi()).retrieveProductKey(this.productBody);
            const { product_keys } = response.data.result;

            if (product_keys.length) {
                this.findProductKeys(product_keys);
            } else {
                this.setNotFoundNotification();
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.isSending = false;
        }
    }

    public findProductKeys(keys: any): void {
        [this.productBody.node1, this.productBody.node2].forEach(node => {
            if (node) {
                const found = keys.find((item: any) => item.node === node);

                if (found) {
                    this.productKeys.push(found.key);
                }
            }

            if (this.productKeys.length === 0) this.setNotFoundNotification();
        });
    }

    public setNotFoundNotification(): void {
        this.setSnackBarNotification({
            msg: 'Product keys not found.',
            type: 'error'
        });
    }

    public copyText(text: string): void {
        navigator.clipboard.writeText(text);
    }
}
