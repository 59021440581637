import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import Auth from '@/store/auth';
import Message from '@/store/message';
import Notification from '@/store/notification';
import ConfigApi from '@/services/api/ConfigApi';

export default class KeepAliveUtil {
    public static interval: number = 0;
    public static attempt: number = 0;

    public static setInterval(func: () => void, time: number = 126000): void {
        this.interval = setInterval(() => {
            func();
        }, time);
    }

    public static clearInterval(): void {
        clearInterval(this.interval);

        this.interval = 0;
    }

    public static async isAuthenticated(): Promise<boolean> {
        const AuthModule = getModule(Auth, store);
        const MessageModule = getModule(Message, store);
        const NotificationModule = getModule(Notification, store);

        if (!AuthModule.isLoggedIn) {
            try {
                const response = await (new ConfigApi()).keepAlive();
                const { authenticated, flash_after_callback, token, user, ask_for_subscriptions } = response.data.result;
                const { version } = response.data;

                if (flash_after_callback) {
                    if (flash_after_callback.message) {
                        NotificationModule.setSnackBarNotification({
                            msg: flash_after_callback.message,
                            type: 'error'
                        });
                    }

                    if (flash_after_callback.request_user) {
                        if (flash_after_callback.user_info) {
                            AuthModule.setUser({
                                firstName: flash_after_callback.user_info.first_name || '',
                                lastName: flash_after_callback.user_info.last_name || '',
                                email: flash_after_callback.user_info.email || '',
                                companyName: flash_after_callback.user_info.company || '',
                                serialNumber: flash_after_callback.user_info.serial_number || '',
                            });
                        }
                        AuthModule.setShowRequestForm(true);
                    }
                }

                AuthModule.setToken(token);
                AuthModule.setVersion(version);
                AuthModule.setLoggedIn(authenticated);
                AuthModule.setShowAskForSubscriptionDialog(ask_for_subscriptions);
                MessageModule.setLength(user.messages_count);
            } catch (e) {
                console.error(e);

                return false;
            }
        }

        return AuthModule.isLoggedIn;
    }
}
