import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import LocalStorageUtil from '@/utils/LocalStorageUtil';

Vue.use(Vuetify);

const opts = {
    theme: {
        dark: LocalStorageUtil.isDarkTheme(),
        themes: {
            light: {
                primary: '#00A3E0',
                secondary: '#DE7C00',
                accent: '#FAFAFA',
                error: '#d12021',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFBA00',
                critical: '#d12021',
                degraded: '#F47F16',
                debug: '#050000',
                stepper: '#FFFFFF',
                textcolor: '#000000',
            },
            dark: {
                primary: '#44befd',
                secondary: '#FE9629',
                accent: '#292929',
                error: '#d12021',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFBA00',
                critical: '#d12021',
                degraded: '#F47F16',
                debug: '#B0B9BF',
                stepper: '#1e1e1e',
                textcolor: '#FFFFFF',
            },
        },
    },
};

export default new Vuetify(opts);
