import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import ValidationUtil from '@/utils/ValidationUtil';
import NotificationModule from '@/store/notification';
import STATUS_CODE_ERROR from '@/models/ErrorMessageModel';

export default class GlobalNotificationHandler {
    public static handleErrorNotification(response: any): void {
        let msg = '';
        const { status } = response;
        const errors: { [key: number]: string } = STATUS_CODE_ERROR;
        const notificationModule = getModule(NotificationModule, store);

        if (Object.keys(errors).includes(status.toString())) {
            msg = errors[status];
        }

        if (status === 422) {
            msg = response.data.errors
                ? ValidationUtil.processSymfonyFormErrors(response.data.errors)
                : response.data.message;
        }

        if (status === 400) {
            msg = response.data.message;
        }

        if (status >= 500) {
            // eslint-disable-next-line
            msg = errors[500];
        }

        if (!response.config.url.includes('keep-alive')) {
            notificationModule.setSnackBarNotification({
                msg,
                type: 'error'
            });
        }
    }

    public static handleLimitedServerAccess(): void {
        const notificationModule = getModule(NotificationModule, store);

        notificationModule.setSnackBarNotification({
            msg: 'Uh oh! Cloud-Based Analytics was unable to contact the server. Please try again.',
            type: 'error'
        });
    }

    public static handleSuccessNotification(response: any): void {
        if (!response?.data?.message) return;
        const notificationModule = getModule(NotificationModule, store);

        notificationModule.setSnackBarNotification({
            msg: response.data.message,
            type: 'success'
        });
    }
}
