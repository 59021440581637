export const MAIN_MENU = [
    {
        icon: 'mdi-office-building',
        title: 'Organizations',
        isVisible: false,
        link: { name: 'customers' },
        path: 'customers',
        tooltip: {
            text: 'Organizations',
            enabled: (): boolean => false,
        },
        roles: ['customers.list'],
    },
    {
        icon: 'mdi-speedometer',
        title: 'Performance',
        isVisible: true,
        link: { name: 'performance' },
        path: 'performance',
        tooltip: {
            text: 'Performance',
        },
        roles: ['customers.performanceTopMenu'],
    },
    {
        icon: 'mdi-lightbulb',
        title: 'Solutions',
        isVisible: true,
        link: { name: 'solutions' },
        path: 'solutions',
        tooltip: {
            text: 'Solutions',
        },
        roles: ['solutions.list'],
    },
    {
        icon: 'mdi-server-network',
        title: 'Nodes',
        isVisible: true,
        link: { name: 'nodes' },
        path: 'nodes',
        tooltip: {
            text: 'Nodes',
        },
    },
    {
        icon: 'mdi-bell',
        title: 'Alerts',
        isVisible: true,
        link: { name: 'alerts' },
        path: 'alerts',
        tooltip: {
            text: 'Alerts',
        },
    },
    {
        icon: 'mdi-chart-line',
        title: 'Explore',
        isVisible: false,
        link: { name: 'explore' },
        path: 'explore',
        tooltip: {
            text: 'Explore',
            enabled: (): boolean => false,
        },
        roles: ['stats.dashboard'],
    },
    {
        icon: 'mdi-account-multiple',
        title: 'Users',
        isVisible: false,
        link: { name: 'users' },
        path: 'users',
        tooltip: {
            text: 'Users',
            enabled: (): boolean => false,
        },
        roles: ['users.list'],
    },
];

export const MAIN_MENU_BUTTONS = [
    {
        icon: 'mdi-message-processing',
        name: 'Messages',
        tooltip: 'Messages',
        link: { name: 'messages' }
    },
];

export const POPUP_MENU = [
    {
        title: 'Profile',
        icon: 'mdi-account',
        isVisible: true,
        external: true,
        eventName: 'myProfile'
    },
    {
        title: 'Change Password',
        icon: 'mdi-key',
        isVisible: true,
        link: { name: 'reset-password', params: { skipValidation: true } },
        external: true,
        eventName: 'changePassword',
        roles: ['profile.password'],
    },
    {
        title: 'Association Token',
        icon: 'mdi-barcode',
        isVisible: true,
        external: true,
        eventName: 'getToken',
    },
    {
        title: 'StorNext RYO Product Key',
        icon: 'mdi-cloud-key',
        isVisible: true,
        external: true,
        eventName: 'storNextKey',
    },
    {
        title: 'Upload StorNext License Report',
        icon: 'mdi-cloud-upload',
        isVisible: true,
        external: true,
        eventName: 'storNextLicenseReport',
    },
    {
        title: 'Log out',
        icon: 'mdi-logout',
        isVisible: true,
        link: { name: 'logout' },
    },
];
