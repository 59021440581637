import 'core-js/stable';
import Vue from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import No_data_to_display from 'highcharts/modules/no-data-to-display';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import loadHighchartsMore from 'highcharts/highcharts-more';
import loadSolidGauge from 'highcharts/modules/solid-gauge';
import Component from 'vue-class-component';
import VueGtag from 'vue-gtag';
import filters from '@/utils/FiltersUtil';
import App from './App.vue';
import store from './store';
import router from './router';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';
import './assets/css/main.css';

require('./error-handler');

loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);
No_data_to_display(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

Vue.config.productionTip = false;

Vue.use(HighchartsVue, Highcharts, loadHighchartsMore);
Vue.use(VueGtag, {
    pageTrackerTemplate(to: { path: any; }) {
        return {
            page_path: to.path
        };
    },
    bootstrap: false
}, router);

Vue.mixin({
    filters,
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
