import { render, staticRenderFns } from "./AlertNotification.vue?vue&type=template&id=657257e6&scoped=true&lang=pug&"
import script from "./AlertNotification.vue?vue&type=script&lang=ts&"
export * from "./AlertNotification.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657257e6",
  null
  
)

export default component.exports