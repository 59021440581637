export default class QueryParamsUtil {
    public static objectToQueryParamsString(obj: any): string {
        return Object.keys(obj)
            .filter(key => obj[key] !== null
                    && obj[key] !== ''
                    && !(Array.isArray(obj[key]) && obj[key].length === 0))
            .map(key => {
                if (obj[key].constructor === Array && obj[key].length) {
                    return obj[key].map((el: string) => `${key}[]=${encodeURIComponent(el)}`).join('&');
                }
                return `${key}=${encodeURIComponent(obj[key])}`;
            }).join('&');
    }
}
