import {
    Module, VuexModule, MutationAction, Mutation
} from 'vuex-module-decorators';
import ProductApi from '@/services/api/ProductApi';
import AlarmsApi from '@/services/api/AlarmsApi';
import BaseSelectInterface from '@/interfaces/BaseSelectInterface';
import TypeSelectInterface from '@/interfaces/TypeSelectInterface';
import ProductFilterTransformer from '@/transformers/ProductFilterTransformer';
import LogsApi from '@/services/api/LogsApi';
import ExploreApi from '@/services/api/ExploreApi';
import ExploreProductApi from '@/services/api/ExploreProductApi';
import CustomersApi from '@/services/api/CustomersApi';

@Module({
    namespaced: true,
    name: 'productModule',
})
class ProductModule extends VuexModule {
    private statusOptions = [
        { value: '0', text: 'Open' },
        { value: '1', text: 'Ack' }
    ];
    private interfaceOptions = [
        { value: '1', text: 'Client' },
        { value: '2', text: 'Storage' },
        { value: '3', text: 'Management' }
    ];
    public isLoadingProductFamilyItems: boolean = false;
    public isLoadingProductSeveritiesItems: boolean = false;
    public jobStatusesOptions: any = {} as unknown;
    public usersAdminLogOptions: any = {} as unknown;
    public allCustomers: any = {} as unknown;
    public productClasses: any = [] as unknown;
    public productFamilies: any = [] as unknown;
    public productModels: any = [] as unknown;
    public productSeverities: any = [] as unknown;
    public productCustomerTypes: any = {} as unknown;
    public alarmAggregations: any = {} as unknown;
    public alarmThresholdCriteria: any = {} as unknown;
    public performanceMetrics: any = [] as unknown;
    public capacityGroups: any = [] as unknown;
    public regionItems: any = [] as unknown;
    public contractCountriesItems: any = [] as unknown;
    public statusItems: any = [] as unknown;
    public transferStatusItems: any = [] as unknown;
    public categoryItems: any = [] as unknown;
    public jobUsersOptions: any = [] as unknown;
    public productBranches: any = [] as unknown;
    public productVersions: any = [] as unknown;
    public productTree: any = {} as unknown;

    public get classes(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformClasses(this.productClasses);
    }

    public get families(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformFamilies(this.productFamilies);
    }

    public get customers(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.allCustomers);
    }

    public get branches(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.productBranches);
    }

    public get versions(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.productVersions);
    }

    public get treeItem(): BaseSelectInterface[] {
        return { ...this.productTree };
    }

    public get models(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformModels(this.productModels);
    }

    public get modelsWithType(): TypeSelectInterface[] {
        return ProductFilterTransformer.transformModelsWithType(this.productModels);
    }

    public get severities(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformSeverities(this.productSeverities);
    }

    public get jobStatuses(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.jobStatusesOptions);
    }

    public get aggregations(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.alarmAggregations, true);
    }

    public get thresholdCriteria(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.alarmThresholdCriteria, true);
    }

    public get usersAdminLog(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.usersAdminLogOptions);
    }

    public get metrics(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.performanceMetrics, true);
    }

    public get groups(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.capacityGroups, true);
    }

    public get statusOptionsList(): BaseSelectInterface[] {
        return [...this.statusOptions];
    }

    public get interfaceOptionsList(): BaseSelectInterface[] {
        return [...this.interfaceOptions];
    }

    public get customerTypes(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.productCustomerTypes, true);
    }

    public get regions(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.regionItems, true);
    }

    public get contractCountries() {
        return (serviceRegion?: string): BaseSelectInterface[] => {
            const filterByCountry = this.contractCountriesItems.reduce((result: any, item: any) => {
                if (!serviceRegion || item.service_region === serviceRegion) {
                    return { ...result, [item.country]: item.country };
                }
                return result;
            }, {});

            return ProductFilterTransformer.transformAndSortObjectToArray(filterByCountry, true);
        };
    }

    public get statuses(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.statusItems, true);
    }

    public get transferStatuses(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.transferStatusItems, true);
    }

    public get categories(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.categoryItems, true);
    }

    public get jobUsers(): BaseSelectInterface[] {
        return ProductFilterTransformer.transformAndSortObjectToArray(this.jobUsersOptions, true);
    }

    @MutationAction({ mutate: ['productClasses'] })
    public async getProductClassItems(): Promise<any> {
        let productClasses: any[] = [];

        try {
            const response = await new ProductApi().getProductClasses();
            productClasses = response.data.result.classes;
        } catch (e) {
            console.error(e);
        }

        return { productClasses };
    }

    @MutationAction({ mutate: ['productFamilies'] })
    public async getProductFamilyItems(): Promise<any> {
        let productFamilies: any[] = [];

        if (!this.isLoadingProductFamilyItems) {
            this.context.commit('setIsLoadingProductFamilyItems', true);

            try {
                const response = await new ProductApi().getProductFamilies();
                productFamilies = response.data.result.families;

                this.context.commit('setIsLoadingProductFamilyItems', false);
            } catch (e) {
                console.error(e);
            }
        }

        return { productFamilies };
    }

    @Mutation
    public setIsLoadingProductFamilyItems(value: boolean): void {
        this.isLoadingProductFamilyItems = value;
    }

    @MutationAction({ mutate: ['productModels'] })
    public async getProductModelItems() {
        let productModels: any[] = [];

        try {
            const response = await new ProductApi().getProductModels();
            productModels = response.data.result.models;
        } catch (e) {
            console.error(e);
        }

        return { productModels };
    }

    @MutationAction({ mutate: ['allCustomers'] })
    public async getAllCustomersItems() {
        let allCustomers: any[] = [];

        try {
            const response = await new CustomersApi().getAllCustomersList();
            allCustomers = response.data.result.customers;
        } catch (e) {
            console.error(e);
        }

        setTimeout(() => {
            this.context.commit('resetAllCustomers');
        }, 15000);

        return { allCustomers };
    }

    @Mutation
    public resetAllCustomers(): void {
        this.allCustomers = [];
    }

    @MutationAction({ mutate: ['productSeverities'] })
    public async getProductSeverityItems() {
        let productSeverities: any[] = [];

        if (!this.isLoadingProductSeveritiesItems) {
            this.context.commit('setIsLoadingProductSeveritiesItems', true);

            try {
                const response = await new ProductApi().getProductSeverities();
                productSeverities = response.data.result.severities;

                this.context.commit('setIsLoadingProductSeveritiesItems', false);
            } catch (e) {
                console.error(e);
            }
        }

        return { productSeverities };
    }

    @Mutation
    public setIsLoadingProductSeveritiesItems(value: boolean): void {
        this.isLoadingProductSeveritiesItems = value;
    }

    @MutationAction({ mutate: ['jobStatusesOptions'] })
    public async getJobStatusesOptionsItems() {
        let jobStatusesOptions: any = {};

        try {
            const response = await new ProductApi().getJobStatuses();
            jobStatusesOptions = response.data.result.statuses;
        } catch (e) {
            console.error(e);
        }

        return { jobStatusesOptions };
    }

    @MutationAction({ mutate: ['jobUsersOptions'] })
    public async getJobUsersOptionsItems() {
        let jobUsersOptions: any = {};

        try {
            const response = await new ExploreApi().getJobUsers();
            jobUsersOptions = response.data.result.users;
        } catch (e) {
            console.error(e);
        }

        return { jobUsersOptions };
    }

    @MutationAction({ mutate: ['productCustomerTypes'] })
    public async getProductCustomerTypes(): Promise<any> {
        let productCustomerTypes: any = {};

        try {
            const response = await new ProductApi().getProductCustomerTypes();
            productCustomerTypes = response.data.result.types;
        } catch (e) {
            console.error(e);
        }

        return { productCustomerTypes };
    }

    @MutationAction({ mutate: ['performanceMetrics'] })
    public async getPerformanceMetricsItems() {
        let performanceMetrics: any[] = [];

        try {
            const response = await new ExploreApi().getPerformanceMetrics();
            performanceMetrics = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { performanceMetrics };
    }

    @MutationAction({ mutate: ['capacityGroups'] })
    public async getCapacityGroupsItems() {
        let capacityGroups: any[] = [];

        try {
            const response = await new ExploreApi().getCapacityGroups();
            capacityGroups = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { capacityGroups };
    }

    @MutationAction({ mutate: ['regionItems'] })
    public async getRegionsItems() {
        let regionItems: any[] = [];

        try {
            const response = await new ExploreApi().getRegionItems();
            regionItems = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { regionItems };
    }

    @MutationAction({ mutate: ['contractCountriesItems'] })
    public async getContractCountriesItems() {
        let contractCountriesItems: any[] = [];

        try {
            const response = await new ExploreApi().getContractCountriesItems();
            contractCountriesItems = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { contractCountriesItems };
    }

    @MutationAction({ mutate: ['statusItems'] })
    public async getStatusesItems() {
        let statusItems: any[] = [];

        try {
            const response = await new ExploreApi().getStatusItems();
            statusItems = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { statusItems };
    }

    @MutationAction({ mutate: ['transferStatusItems'] })
    public async getTransferStatusesItems() {
        let transferStatusItems: any[] = [];

        try {
            const response = await new ExploreApi().getTransferStatusItems();
            transferStatusItems = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { transferStatusItems };
    }

    @MutationAction({ mutate: ['categoryItems'] })
    public async getCategoryItems() {
        let categoryItems: any[] = [];

        try {
            const response = await new ExploreApi().getCategoryItems();
            categoryItems = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { categoryItems };
    }

    @MutationAction({ mutate: ['usersAdminLogOptions'] })
    public async getUsersAdminLogOptionsItems() {
        let usersAdminLogOptions: any[] = [];

        try {
            const response = await new LogsApi().getUsersAdminLogList();
            usersAdminLogOptions = response.data.result.users;
        } catch (e) {
            console.error(e);
        }

        return { usersAdminLogOptions };
    }

    @MutationAction({ mutate: ['alarmAggregations'] })
    public async getAlarmAggregationsItems() {
        let alarmAggregations: any = {};

        try {
            const response = await new AlarmsApi().getAlarmAggregationList();
            alarmAggregations = response.data.result.aggregations;
        } catch (e) {
            console.error(e);
        }

        return { alarmAggregations };
    }

    @MutationAction({ mutate: ['alarmThresholdCriteria'] })
    public async getAlarmThresholdCriteria() {
        let alarmThresholdCriteria: any = {};

        try {
            const response = await new AlarmsApi().getAlarmThresholdCriteriaList();
            alarmThresholdCriteria = response.data.result.threshold_criteria;
        } catch (e) {
            console.error(e);
        }

        return { alarmThresholdCriteria };
    }

    @MutationAction({ mutate: ['productBranches'] })
    public async getProductBranchItems(item: any): Promise<any> {
        let productBranches: any[] = [];

        try {
            const response = await new ExploreProductApi().getProductReleaseBranchesList(item);
            productBranches = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { productBranches };
    }

    @MutationAction({ mutate: ['productVersions'] })
    public async getProductVersionItems(item: any): Promise<any> {
        let productVersions: any[] = [];

        try {
            const response = await new ExploreProductApi().getProductReleaseVersionsList(item);
            productVersions = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { productVersions };
    }

    @MutationAction({ mutate: ['productTree'] })
    public async getProductTreeItem(treeId: any): Promise<any> {
        let productTree: any = {};

        try {
            const response = await new ExploreProductApi().getProductReleaseTree(treeId);
            productTree = response.data.result;
        } catch (e) {
            console.error(e);
        }

        return { productTree };
    }
}

export default ProductModule;
