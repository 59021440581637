import {
    Module, VuexModule, MutationAction, Mutation
} from 'vuex-module-decorators';
import MessagesApi from '@/services/api/MessagesApi';

@Module({
    namespaced: true,
    name: 'messageModule',
})
class MessageModule extends VuexModule {
    public length: number = 0;

    @MutationAction({ mutate: ['length'] })
    public async getAllMessagesLength(): Promise<any> {
        const response = await new MessagesApi().getAllMessages();
        const length = response.data.result.total;

        return { length };
    }

    public get messagesLength(): number {
        return this.length;
    }

    @Mutation
    public setLength(count: number): void {
        this.length = count;
    }
}

export default MessageModule;
