export default {
    truncate: (v: string, limit:number) => {
        let value = v;
        if (!value) { return ''; }
        value = value.toString();
        if (value.length > limit) {
            value = `${value.substring(0, (limit - 3))}...`;
        }
        return value;
    },

    // The filter checks the string for ip addresses and replaces all occurrences with the format ***.***.***.NNN
    // For example, the line: "This node has two ip addresses - 123.32.12.19 and 12.124.231.122".
    // => "This node has two IP addresses ***.**.**.19 and **.***.***.122"
    // Or the line with ip address only: "194.231.10.123" => "***.***.**.123
    maskIpAddresses: (value: string, isShowIpAddress: boolean) => {
        function maskIp(ipString: string): string {
            const octets = ipString.split('.');

            return octets.map((octet: string, index: number) => {
                if (index < 3) {
                    return octet.replace(/\d/g, '*');
                }
                return octet;
            }).join('.');
        }
        const ipPattern = /\b(?:\d{1,3}\.){3}\d{1,3}\b/g;

        if (!isShowIpAddress && value?.match(ipPattern)) {
            return value.replace(ipPattern, maskIp);
        }

        return value;
    }

};
