import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import Auth from '@/store/auth';
import User from '@/store/user';
import Menu from '@/store/menu';
import KeepAliveUtil from '@/utils/KeepAliveUtil';
import AuthApi from '@/services/api/AuthApi';
import { DEFAULT_START_PAGE } from '@/router';

const AuthModule = getModule(Auth, store);
const UserModule = getModule(User, store);
const MenuModule = getModule(Menu, store);

export const guard = async (to: Route, from: Route, next: (url?: { path: string, query?: any }) => void): Promise<void> => {
    if (await KeepAliveUtil.isAuthenticated()) {
        const entryUrl = localStorage.getItem('entry-url');
        if (entryUrl) {
            localStorage.setItem('entry-url', '');
            const urlObj: { path: string, query?: any } = { path: entryUrl };

            const entryQuery = localStorage.getItem('entry-query');
            if (entryQuery) {
                localStorage.setItem('entry-query', '');
                urlObj.query = entryQuery;
            }

            return next(urlObj);
        }

        return next();
    }

    localStorage.setItem('entry-url', to.path);
    localStorage.setItem('entry-query', Object.keys(to.query).length ? String(to.query) : '');

    return next({ path: '/login' });
};

export const isLoggedIn = async (to: Route, from: Route, next: (url?: string) => void): Promise<void> => {
    if (await KeepAliveUtil.isAuthenticated()) {
        return next(DEFAULT_START_PAGE.path);
    }

    return next();
};

export const logoutGuard = (to: Route, from: Route, next: (url?: string) => void): void => {
    UserModule.resetUserRoleItems();
    MenuModule.resetMenu();
    AuthModule.logout().then(() => {
        next('/login');
    });
};

export const resetPasswordGuard = (to: Route, from: Route, next: (url?: string) => void) => {
    if (to.params.skipValidation) {
        return next();
    }
    const { id, token } = to.query;
    if (!token || !id) {
        return next('/');
    }
    return new AuthApi().validateResetPasswordLink(id, token).then(() => next(), () => {
        // show error?
        next('/');
    });
};
