import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import store from '@/store';
import { productKeysPayloadData } from '@/components/common/StorNextKeyDialog.vue';

export default class ProductKeyApi {
    protected backendURL: string = store.getters['siteConfigModule/getBackend'];

    public getProductKeyInfo(agent_id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/agents/${agent_id}/stornext/product-keys/info`, {});
    }

    public retrieveProductKey(payload: productKeysPayloadData): AxiosPromise {
        return HttpAdapter.post(`${this.backendURL}/web-api/agents/stornext/product-keys`, payload);
    }
}
