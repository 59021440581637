
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';

const theme = namespace('themeModule');

@Component
export default class AgreementLink extends Vue {
    @theme.Getter
    protected showTooltips!: boolean;
}
