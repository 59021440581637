
import { namespace } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import CustomersApi from '@/services/api/CustomersApi';
import BaseCustomerDialog from '@/components/customers/BaseCustomerDialog';
import { CustomerInterface } from '@/interfaces/CustomerInterface';
import BaseSelectInterface from '@/interfaces/BaseSelectInterface';

const product = namespace('productModule');
const auth = namespace('authModule');

@Component
export default class ToggleFavoriteCustomerNotifDialog extends BaseCustomerDialog {
    @Prop({ required: false, type: Boolean, default: false })
    public isStarButton!: boolean;
    @Prop({ required: true, type: Object })
    public customer!: CustomerInterface;

    @product.Getter
    public severities!: BaseSelectInterface[];
    @product.Action
    public getProductSeverityItems!: () => Promise<any>;
    @auth.Getter
    public canShowAskForSubscriptionDialog!: boolean;
    @auth.Getter
    public hasRequireCompletedProfile!: boolean;

    public isSending: boolean = false;

    @Watch('dialog')
    public dialogChanged(): void {
        if (this.dialog) {
            if (!this.severities.length) {
                this.getProductSeverityItems();
            }
        }
    }

    @Watch('canShowAskForSubscriptionDialog')
    public canShowAskForSubscriptionDialogChanged(): void {
        this.setShow();
    }

    @Watch('hasRequireCompletedProfile')
    public hasRequireCompletedProfileChanged(): void {
        this.setShow();
    }

    public setShow(): void {
        if (this.canShowAskForSubscriptionDialog && !this.hasRequireCompletedProfile && !this.dialog && !this.isStarButton) {
            this.dialog = true;
        }
    }

    public closeAndToggleFavorite(): void {
        if (this.canToggleFavorite) {
            this.toggleFavorite().then(() => {
                this.dialog = false;
                this.$emit('updated');
            });
        } else {
            this.dialog = false;
            this.$emit('updated');
        }
    }

    public setAllAlerts(): void {
        const allSeverities = this.severities.map((severity: any) => severity.value);

        this.updateSubscriptions(allSeverities, allSeverities).then(() => {
            this.closeAndToggleFavorite();
        });
    }

    public setHighAlerts(): void {
        const severitiesCritical = this.severities.find(severity => severity.text === 'Critical');
        const severitiesCriticalValue = severitiesCritical
            ? severitiesCritical.value
            : undefined;

        const severitiesNotice = this.severities.find(severity => severity.text === 'Notice');
        const severitiesNoticeValue = severitiesNotice
            ? severitiesNotice.value
            : undefined;

        let severitiesEmail;
        let severitiesBatch;

        if (severitiesCriticalValue) {
            severitiesEmail = this.severities
                .map((severity: any) => severity.value)
                .filter((value: any) => value >= severitiesCriticalValue);
        }

        if (severitiesNoticeValue) {
            severitiesBatch = this.severities
                .map((severity: any) => severity.value)
                .filter((value: any) => value >= severitiesNoticeValue);
        }

        this.updateSubscriptions(severitiesEmail, severitiesBatch).then(() => {
            this.closeAndToggleFavorite();
        });
    }

    public setEmptyAlerts(): void {
        this.updateSubscriptions().then(() => {
            this.closeAndToggleFavorite();
        });
    }

    public async updateSubscriptions(
        severitiesEmail: Array<number>|undefined = undefined,
        severitiesBatch: Array<number>|undefined = undefined
    ): Promise<void> {
        this.isSending = true;

        await (new CustomersApi()).updateCustomerSubscriptions(this.customer.id, severitiesEmail, severitiesBatch).finally(() => {
            this.isSending = false;
        });
    }

    public async toggleFavorite(): Promise<void> {
        this.isSending = true;

        await (new CustomersApi()).toggleFavoriteCustomer(this.customer.id).catch(error => {
            console.error(error);
        }).finally(() => {
            this.isSending = false;
        });
    }

    public get canToggleFavorite(): boolean {
        return (this.customer.acl && this.customer.acl['customers.toggleFavorite']) || false;
    }

    public get mainText(): string {
        return this.isStarButton
            ? 'Would you like to receive email notifications of alerts for this organization?'
            : 'Would you like to receive email notifications of alerts from Quantum’s Cloud-Based Analytics?';
    }
}
