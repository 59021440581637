import BaseSelectInterface from '@/interfaces/BaseSelectInterface';
import TypeSelectInterface from '@/interfaces/TypeSelectInterface';
import SortUtil from '@/utils/SortUtil';

export default class ProductFilterTransformer {
    public static transformModels(models: any[]): BaseSelectInterface[] {
        return models.map(item => ({ text: item.model_name, value: item.id }));
    }

    public static transformModelsWithType(models: any[]): TypeSelectInterface[] {
        return models.map(item => ({ text: item.model_name, value: item.id, type: item.family_id }));
    }

    public static sortModels(models: any[]): BaseSelectInterface[] {
        return SortUtil.sortObjectByText(models.map(item => ({ text: item.text, value: item.value })));
    }

    public static transformFamilies(families: any[]): BaseSelectInterface[] {
        return families.map(item => ({ text: item.name, value: item.id }));
    }

    public static transformClasses(classes: any[]): BaseSelectInterface[] {
        return classes.map(item => ({ text: item.class_name, value: item.id }));
    }

    public static transformSeverities(severities: any[]): BaseSelectInterface[] {
        const transformed: BaseSelectInterface[] = [];

        Object.keys(severities).forEach(key => {
            const id = Number(key);
            transformed.unshift({
                value: id,
                text: severities[id]
            });
        });

        return transformed;
    }

    public static transformAndSortObjectToArray(obj: any, isValueString: boolean = false): BaseSelectInterface[] {
        const transformed: BaseSelectInterface[] = [];

        Object.keys(obj).forEach(key => {
            const id = isValueString ? String(key) : Number(key);
            transformed.push({
                value: id,
                text: obj[id]
            });
        });

        transformed.sort((a, b) => {
            const aNum = parseInt(a.text, 10);
            const bNum = parseInt(b.text, 10);
            if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.text.localeCompare(b.text);
        });

        return transformed;
    }

    public static filteredModelsWithType(models: any, product_family_id: number = 0, withAllModels: boolean = false): BaseSelectInterface[] {
        let filtered = models;
        if (product_family_id > 0) {
            filtered = filtered.filter((item: { type: number; }) => (item.type === Number(product_family_id)));
        }
        filtered = ProductFilterTransformer.sortModels(filtered);

        if (withAllModels) {
            filtered = [{ text: 'All Models', value: -1 }, ...filtered];
        }

        return filtered;
    }

    public static transformUsersWithMyself(obj: BaseSelectInterface[], userId: number): any[] {
        return [{ value: `${userId}`, text: 'Myself' }, { divider: true }, ...obj];
    }
}
