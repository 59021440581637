
import { namespace } from 'vuex-class';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { setOptions, bootstrap, set } from 'vue-gtag';
import AppBar from '@quantum/common-vue-components/src/components/AppBar/AppBar.vue';
import QFooter from '@quantum/common-vue-components/src/components/Footer/Footer.vue';
import LeftMenu from '@quantum/common-vue-components/src/components/LeftMenu/LeftMenu.vue';
import ListenerUtil from '@/utils/ListenerUtil';
import ConfigApi from '@/services/api/ConfigApi';
import KeepAliveUtil from '@/utils/KeepAliveUtil';
import MenuInterface from '@/interfaces/MenuInterface';
import SettingsMenuInterface from '@/interfaces/SettingsMenuInterface';
import SettingsMenu from '@/models/menu/SettingsMenu';
import { MAIN_MENU, MAIN_MENU_BUTTONS, POPUP_MENU } from '@/models/menu/MenuModel';
import AuthenticatedInterface from '@/interfaces/AuthenticatedInterface';
import NotificationInterface from '@/interfaces/NotificationInterface';
import AlertNotification from '@/components/common/AlertNotification.vue';
import ToastNotification from '@/components/common/ToastNotification.vue';
import ImpersonationHeader from '@/components/common/ImpersonationHeader.vue';
import MenuButtonBadgeInterface from '@/interfaces/MenuButtonBadgeInterface';
import AgreementLink from '@/components/common/AgreementLink.vue';
import FeedbackLink from '@/components/common/FeedbackLink.vue';
import GlobalSearch from '@/views/GlobalSearch.vue';
import ChangePasswordDialog from '@/components/users/ChangePasswordDialog.vue';
import GetTokenDialog from '@/components/common/GetTokenDialog.vue';
import MyProfileDialog from '@/components/common/MyProfileDialog.vue';
import AskForSubscriptionCustomerDialog from '@/components/customers/ToggleFavoriteCustomerNotifDialog.vue';
import PageLoader from '@/components/common/PageLoader.vue';
import StorNextKeyDialog from '@/components/common/StorNextKeyDialog.vue';
import StorNextLicenseReportDialog from '@/components/common/StorNextLicenseReportDialog.vue';

const siteConfig = namespace('siteConfigModule');
const auth = namespace('authModule');
const menu = namespace('menuModule');
const messages = namespace('messageModule');
const theme = namespace('themeModule');
const notification = namespace('notificationModule');

@Component({
    metaInfo: {
        title: '',
        titleTemplate: titleChunk => (titleChunk ? `${titleChunk} - Cloud-Based Analytics` : 'Cloud-Based Analytics')
    },
    components: {
        AppBar,
        QFooter,
        LeftMenu,
        AlertNotification,
        ToastNotification,
        ImpersonationHeader,
        AgreementLink,
        FeedbackLink,
        GlobalSearch,
        ChangePasswordDialog,
        GetTokenDialog,
        MyProfileDialog,
        AskForSubscriptionCustomerDialog,
        PageLoader,
        StorNextKeyDialog,
        StorNextLicenseReportDialog
    }
})

export default class App extends Vue {
    public mainMenu: MenuInterface[] = MAIN_MENU.map(item => ({ ...item }));
    public settingsMenuItems: SettingsMenuInterface[] = [];
    public mainMenuButtons: MenuButtonBadgeInterface[] = MAIN_MENU_BUTTONS;
    public popupMenu: MenuInterface[] = POPUP_MENU;
    public forceRerenderKey: number = 0;
    @siteConfig.Getter
    public getUIVersion!: string;
    @siteConfig.Getter
    public supportLink!: string;
    @siteConfig.Getter
    public quantumLink!: string;
    @auth.Getter
    public isLoggedIn!: boolean;
    @menu.Getter
    public menu!: MenuInterface[];
    @theme.Getter
    public menuMinimized!: boolean;
    @theme.Getter
    public darkTheme!: boolean;
    @theme.Getter
    public showTooltips!: boolean;
    @theme.Getter
    public showIpAddresses!: boolean;
    @theme.Mutation
    public changeMinimized!: () => void;
    @theme.Mutation
    public changeTheme!: () => void;
    @theme.Mutation
    public changeTooltips!: () => void;
    @theme.Mutation
    public changeHideIpAddresses!: () => void;
    @notification.Mutation
    // eslint-disable-next-line no-shadow
    public setAlertNotification!: (notification: NotificationInterface) => void;
    @notification.Mutation
    public clearAlertNotification!: () => void;
    @notification.Mutation
    // eslint-disable-next-line no-shadow
    public setSnackBarNotification!: (notification: NotificationInterface) => void;
    @notification.Getter
    public isAlertNotified!: boolean;
    @auth.Action
    public logout!: () => Promise<any>;
    @auth.Getter
    public version!: string;
    @auth.Getter
    public userName!: string;
    @auth.Getter
    public authenticatedUser!: AuthenticatedInterface;
    @auth.Getter
    public authenticatedCustomerId!: number;
    @auth.Getter
    public isImpersonated!: boolean;
    @auth.Action
    public getAuthenticatedUser!: () => Promise<any>;
    @auth.Getter
    public accessControlList!: Record<string, boolean>;
    @messages.Getter
    public messagesLength!: number;
    @messages.Action
    public getAllMessagesLength!: () => Promise<any>;
    @messages.Mutation
    public setLength!: (count: number) => void;
    @auth.Mutation
    public setLoggedIn!: (state: boolean) => void;
    @auth.Mutation
    public setToken!: (token: string) => void;
    @auth.Mutation
    public setShowRequestForm!: (request: boolean) => void;
    @auth.Getter
    public canSeeCustomers!: boolean;
    @auth.Getter
    public canUseGlobalSearch!: boolean;

    public created(): void {
        this.setGoogleAnalytics();
        this.setSettingsMenuItems();

        ListenerUtil.addLogoutListener();

        KeepAliveUtil.setInterval(this.checkConnection);
    }

    public setSettingsMenuItems(): void {
        const settingsMenuItems = JSON.parse(JSON.stringify(SettingsMenu));

        this.settingsMenuItems = settingsMenuItems.map((item: any) => {
            const copyItem = { ...item };
            const themePath = this.$vuetify.theme.dark ? 'dark' : 'light';

            if (copyItem.icon.src && copyItem.icon.src[themePath]) {
                copyItem.icon.src = copyItem.icon.src[themePath];
            }

            return copyItem;
        });
    }

    public branding() {
        let logo = '';
        let logoMini = '';

        if (this.$vuetify.theme.dark) {
            // eslint-disable-next-line global-require
            logo = require('@/assets/dark/logo.png');
            // eslint-disable-next-line global-require
            logoMini = require('@/assets/dark/logo-mini.png');
        } else {
            // eslint-disable-next-line global-require
            logo = require('@/assets/light/logo.png');
            // eslint-disable-next-line global-require
            logoMini = require('@/assets/light/logo-mini.png');
        }

        return {
            logo,
            logoMini,
            logoSubHeader: '<span style="text-transform:none;white-space: nowrap;font-family:helvetica;color:#00b6f1">Cloud-Based Analytics</span>',
        };
    }

    public updateVMain(): void {
        this.forceRerenderKey += 1;
    }

    @Watch('darkTheme')
    public themeChanged(isDarkTheme: boolean): void {
        this.$vuetify.theme.dark = isDarkTheme;
        this.setSettingsMenuItems();
    }

    @Watch('showTooltips')
    public showTooltipsChanged(isShowTooltips: boolean): void {
        this.$store.state.tooltips = isShowTooltips;
    }

    @Watch('messagesLength')
    public messagesLengthChanged() {
        if (this.messagesLength) {
            this.mainMenuButtons = MAIN_MENU_BUTTONS.map(
                item => ({ ...item, notification: { color: '#8b2525', text: this.messagesLength } })
            );
        } else {
            this.mainMenuButtons = MAIN_MENU_BUTTONS;
        }
    }

    @Watch('isLoggedIn')
    public loggedIn(isLoggedIn: boolean): void {
        if (isLoggedIn) {
            this.getAuthenticatedUser().then(() => {
                set({
                    user_properties: {
                        access_level: this.authenticatedUser.access_level,
                    }
                });
                this.setMenuRestrictions(this.mainMenu);
                this.setMenuRestrictions(this.popupMenu);
                this.setMenuRestrictions(this.menu);
            });

            this.getAllMessagesLength();
        }
    }

    public setMenuRestrictions(menuItems: MenuInterface[]): void {
        menuItems.forEach((item: MenuInterface) => {
            if (item.roles) {
                const isVisible: boolean = item.roles.some((role: string) => this.accessControlList[role]);
                Object.assign(item, { isVisible });
            }
        });
    }

    public async checkConnection(): Promise<void> {
        try {
            const response = await (new ConfigApi()).keepAlive();
            const { authenticated, flash_after_callback, token, user } = response.data.result;
            const { version } = response.data;
            const { currentRoute } = this.$router;
            const ignoreUrls: any = ['login', 'forgot-password', 'reset-password'];

            if (version !== this.version && currentRoute.name !== 'reset-password') {
                window.location.reload();
            }

            KeepAliveUtil.attempt = 1;

            if (this.isAlertNotified) {
                this.clearAlertNotification();
            }

            if (flash_after_callback) {
                if (flash_after_callback.message) {
                    this.setSnackBarNotification({
                        msg: flash_after_callback.message,
                        type: 'error'
                    });
                }

                if (flash_after_callback.request_user) {
                    this.setShowRequestForm(true);
                    this.$router.push({ name: 'login' });
                }
            }

            this.setToken(token);
            this.setLength(user.messages_count);

            if (!authenticated && ignoreUrls.indexOf(currentRoute.name) === -1) {
                this.setSnackBarNotification({
                    msg: 'Session expired!',
                    type: 'error'
                });

                this.setLoggedIn(false);

                this.$router.push({ name: 'login' });
            }
        } catch (e) {
            console.error(e);

            if (KeepAliveUtil.attempt === 3) {
                this.setAlertNotification({
                    msg: 'Uh oh! CBA is having trouble contacting the server. Trying again in 2 minutes…',
                    type: 'error'
                });

                KeepAliveUtil.attempt = 1;
            }

            KeepAliveUtil.attempt += 1;
        }
    }

    public setShowMenuGlobalSearch(): void {
        (this.$refs.globalSearchMenu as any).setShow();
    }

    public setShowChangePasswordDialog(): void {
        (this.$refs.changePasswordGeneralDialog as any).setShow();
    }

    public setShowGetTokenDialog(): void {
        (this.$refs.getTokenGeneralDialog as any).setShow();
    }

    public setShowMyProfileDialog(): void {
        (this.$refs.myProfileGeneralDialog as any).setShow();
    }

    public setShowStorNextKeyDialog(): void {
        (this.$refs.storNextKeyDialog as any).setShow();
    }

    public setShowStorNextLicenseReportDialog(): void {
        (this.$refs.storNextLicenseReportDialog as any).setShow();
    }

    public get getUserName(): string {
        if (this.$vuetify.breakpoint.lg && this.userName.length > 40) {
            return `${this.userName.substring(0, 40)}...`;
        }

        if (this.$vuetify.breakpoint.lgAndUp && this.userName.length > 65) {
            return `${this.userName.substring(0, 65)}...`;
        }

        if (this.userName.length > 30) {
            return `${this.userName.substring(0, 30)}...`;
        }

        return this.userName;
    }

    public setGoogleAnalytics(): void {
        let gtagId = '';
        const domain = window.location.hostname.replace('www.', '').replace('.quantum.com', '');
        switch (domain) {
            // dev
            case 'customer-as-dev':
                gtagId = 'G-GZFH93ETMM';
                break;
            // stage
            case 'customer-as-stage':
                gtagId = 'G-FFLDJKCPRY';
                break;
            // prod
            case 'insight':
                gtagId = 'G-D50BH10CHF';
                break;
            // local development
            case 'localhost':
            default:
                gtagId = 'G-ZX0WLWZEB4';
                break;
        }

        setOptions({
            config: { id: gtagId }
        });

        bootstrap();
    }

    public isActive(menuItem: any): boolean {
        return menuItem.path === (this.menu[0] && this.menu[0].parent);
    }
}
