
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import DebounceInput from '@/components/common/DebounceInput.vue';
import GlobalSearchApi from '@/services/api/GlobalSearchApi';
import { ItemGlobalSearchInterface } from '@/interfaces/GlobalSearchInterface';
import HeartChart from '@/components/common/HeartChart.vue';
import NodeFlag from '@/components/common/NodeFlag.vue';

const auth = namespace('authModule');
const theme = namespace('themeModule');

@Component({
    components: {
        DebounceInput,
        HeartChart,
        NodeFlag,
    },
})
export default class GlobalSearch extends Vue {
    public dialog: boolean = false;
    public search: string = '';
    public isLoading: boolean = false;
    public result: Array<ItemGlobalSearchInterface> = [];
    public hintResult: number = -1;
    @auth.Getter
    public isLoggedIn!: boolean;
    @theme.Getter
    public showTooltips!: boolean;

    public async getSearch(): Promise<void> {
        if (this.search) {
            this.isLoading = true;

            new GlobalSearchApi().getGlobalSearch(this.search).then(response => {
                this.result = response.data;
                if (this.result) {
                    this.hintResult = this.result.length;
                    this.result.forEach((item: any) => {
                        if (item.category === 'node') {
                            const fullName = item.name.split(' (SN:');
                            const itemNew = item;
                            // eslint-disable-next-line prefer-destructuring
                            itemNew.display_name = fullName[0];
                            // eslint-disable-next-line prefer-destructuring
                            itemNew.serial = fullName[1];
                        }
                    });
                    this.checkSerialNumber();
                } else {
                    this.hintResult = 0;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        } else {
            this.hintResult = -1;
            this.result = [];
        }
    }

    public checkSerialNumber(): void {
        if (this.result.length === 1) {
            const serialNumber = / \(SN:(\S+)\)/.exec(this.result[0].name);
            if (serialNumber !== null && serialNumber[1] === this.search) this.goToLink();
        }
    }

    public setShow(): void {
        this.dialog = !this.dialog;
    }

    public closeMenu(): void {
        this.$emit('close');
    }

    public get arrCategories(): Array<Array<ItemGlobalSearchInterface>> {
        return [
            this.result.filter(item => item.category === 'organization'),
            this.result.filter(item => item.category === 'solution'),
            this.result.filter(item => item.category === 'node')
        ];
    }

    public getHeaderGroup(items: Array<ItemGlobalSearchInterface>): string {
        let str = items[0].category;
        str = str && str[0].toUpperCase() + str.slice(1);
        str = items.length > 1 ? `${str}s` : str;
        return str;
    }

    public getHeartTooltip(item: any): string {
        if (!item.health_score_reason) return `${item.health_score}%`;

        return item.health_score_reason;
    }

    public goToLink(): void {
        this.closeMenu();
        this.$router.push(
            {
                name: this.result[0].action,
                params: {
                    id: String(this.result[0].id)
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
        );
    }

    public getResult(): number {
        return this.hintResult;
    }

    public goToFirstLink(): void {
        if (this.result.length) {
            this.goToLink();
        }
    }
}
