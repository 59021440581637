
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import CommonRules from '@/rules/CommonRules';
import UsersApi from '@/services/api/UsersApi';
import PasswordRules from '@/rules/PasswordRules';
import ProfileApi from '@/services/api/ProfileApi';

const auth = namespace('authModule');
const theme = namespace('themeModule');

@Component
export default class ChangePasswordDialog extends Vue {
    @Prop({ required: false, type: Number })
    public id!: number;
    @Prop({ required: false, type: Boolean, default: false })
    public isMenuItem!: boolean;
    @Prop({ required: false, type: Boolean, default: false })
    public isGeneralDialog!: boolean;
    @auth.Action
    public changePassword!: (password: string) => Promise<any>;
    @theme.Getter
    public showTooltips!: boolean;
    public dialog: boolean = false;
    public commonRules = CommonRules;
    public passwordRules = PasswordRules;
    public valid = true;
    public isSending: boolean = false;
    public newPassword: string = '';
    public confirmPassword: string = '';
    public newPasswordShow = false;
    public confirmPasswordShow = false;

    @Watch('dialog')
    public dialogChanged(): void {
        if (this.dialog) {
            this.$emit('closeMenu');
        }
    }

    public setShow(): void {
        this.dialog = true;
    }

    public close(): void {
        this.dialog = false;
        this.isSending = false;

        this.newPassword = '';
        this.confirmPassword = '';
        this.newPasswordShow = false;
        this.confirmPasswordShow = false;

        (this.$refs.form as any).resetValidation();
    }

    public async save(): Promise<void> {
        try {
            this.isSending = true;
            if (this.isGeneralDialog) {
                await (new ProfileApi()).changePassword(this.newPassword);
            } else {
                await (new UsersApi()).changePassword(this.id, this.newPassword, this.confirmPassword);
            }

            this.close();
        } catch (e) {
            this.isSending = false;
            console.error(e);
        }
    }
}
