import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import { UserInterface } from '@/interfaces/UserInterface';
import BaseApiClient from '@/services/api/BaseApiClient';

export default class UsersApi extends BaseApiClient {
    protected type: string = 'users';

    public getDefaultUser(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/users/edit/new`);
    }

    public getUserById(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/users/edit/${id}`);
    }

    public getRoles(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/misc/user-roles`);
    }

    public getAllRoles(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/misc/all-user-roles`);
    }

    public getDemographic(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/misc/demographic`);
    }

    public createUser(user: UserInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/edit/new`, user);
    }

    public updateUser(user: UserInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/edit/${user.id}`, user);
    }

    public disableUsers(ids: number[]): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/disable`, { ids });
    }

    public enableUsers(ids: number[]): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/enable`, { ids });
    }

    public deleteUsers(ids: number[]): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/delete`, { ids });
    }

    public getUserInfo(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/users/${id}`);
    }

    public getUserSubscriptions(params: any): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/users/${params.userId}/subscriptions`,
            {
                params: { ...params }
            }
        );
    }

    public setUserSubscriptions(
        userId: number,
        solution_id: number,
        severities: Array<number>|undefined,
        severities_digest?: Array<number>|undefined
    ): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/${userId}/subscriptions`, {
            solution_id,
            severities,
            severities_digest,
        });
    }

    public sendVerificationEmail(id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/verify/${id}`);
    }

    public changePassword(id: number, newPassword: string, confirmPassword: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/password/${id}`, {
            password: newPassword,
            password_confirmation: confirmPassword,
        });
    }

    public startImpersonateUser(user_id: number) {
        return HttpAdapter.post(`${this.backend}/web-api/users/${user_id}/impersonate`);
    }

    public endImpersonateUser(): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/impersonate/cancel`);
    }

    public createPassword(id: number, newPassword: string, confirmPassword: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/password/${id}/set`, {
            password: newPassword,
            password_confirmation: confirmPassword,
        });
    }

    public clearPassword(id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/users/password/${id}/clear`);
    }
}
