
import { namespace } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NodeInterface } from '@/interfaces/Nodes/NodeInterface';

const theme = namespace('themeModule');

@Component
export default class NodeFlag extends Vue {
    @Prop({ required: true })
    public node!: NodeInterface;

    @theme.Getter
    protected showTooltips!: boolean;

    public get isShowFlag(): boolean {
        return !!(this.node.dual_controller || this.node.proxy_node || this.node.myriad_node_type);
    }

    public get flagConfig(): any {
        let config = null;

        if (this.isShowFlag) {
            const tooltipClass = this.showTooltips ? 'tooltip-on' : 'tooltip-off';

            if (this.node.dual_controller) {
                config = {
                    class: tooltipClass,
                    icon: 'mdi-alpha-c-circle',
                    tooltip: 'Combined Reporting (Dual Controller Node)'
                };
            }

            if (this.node.proxy_node) {
                config = {
                    class: this.showTooltips ? `${tooltipClass} purple--text` : tooltipClass,
                    icon: 'mdi-alpha-p-circle',
                    tooltip: 'Proxy Node (JSU/No Direct Connection)'
                };
            }

            if (this.node.myriad_node_type) {
                config = {
                    class: this.showTooltips ? `${tooltipClass} blue--text` : tooltipClass,
                    icon: `mdi-alpha-${this.node.myriad_node_type[0]}-circle`,
                    tooltip: this.node.myriad_node_type_description
                };
            }

            return config;
        }

        return null;
    }
}
