export default [
    {
        icon: { name: 'mdi-information' },
        title: 'About',
        isVisible: true,
        link: { url: '/about' },
        tooltip: {
            text: 'About',
            enabled: (): boolean => true,
        },
    },
    {
        icon: { name: 'mdi-help-circle' },
        title: 'Help',
        isVisible: true,
        link: { url: 'https://www.quantum.com/CBADocs', target: '_blank' },
        tooltip: {
            text: 'Help',
            enabled: (): boolean => false,
        },
    },
    {
        icon: { name: 'mdi-headset' },
        title: 'Support',
        isVisible: true,
        link: { url: 'https://www.quantum.com/en/service-support', target: '_blank' },
        tooltip: {
            text: 'Support',
            enabled: (): boolean => false,
        },
    },
    {
        icon: {
            src: {
                // eslint-disable-next-line global-require
                dark: require('@/assets/logo-quantum-mini-white.png'),
                // eslint-disable-next-line global-require
                light: require('@/assets/logo-quantum-mini-blue.png'),
            },
            custom: true,
        },
        title: 'Quantum.com',
        isVisible: true,
        link: { url: 'https://www.quantum.com/', target: '_blank' },
        tooltip: {
            text: 'Support',
            enabled: (): boolean => false,
        },
    },
    {
        icon: { name: 'mdi-email' },
        title: 'Feedback',
        isVisible: true,
        link: { url: '/feedback' },
        tooltip: {
            text: 'Feedback',
            enabled: (): boolean => false,
        },
    },
];
