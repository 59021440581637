import {
    Module,
    VuexModule,
    Mutation,
    Action
} from 'vuex-module-decorators';
import AuthApi from '@/services/api/AuthApi';
import ConfigApi from '@/services/api/ConfigApi';
import ProfileApi from '@/services/api/ProfileApi';
import ProfileInterface from '@/interfaces/ProfileInterface';
import AuthenticatedInterface from '@/interfaces/AuthenticatedInterface';

@Module({
    namespaced: true,
    name: 'authModule',
})
class AuthModule extends VuexModule {
    private loggedIn: boolean = false;
    private showAskForSubscriptionDialog: boolean = false;
    private userData: ProfileInterface = {} as ProfileInterface;
    private token: string = '';
    private keepAliveVersion: string = '';
    private authenticated: AuthenticatedInterface = {} as AuthenticatedInterface;
    private acl: Record<string, boolean> = {};
    private showRequestForm: boolean = false;

    public get isLoggedIn(): boolean {
        return this.loggedIn;
    }

    public get authenticatedUser(): AuthenticatedInterface {
        return this.authenticated;
    }

    public get authenticatedCustomerId(): number {
        return this.authenticated.customer_id || 0;
    }

    public get user(): ProfileInterface {
        return this.userData;
    }

    public get userEmail(): string {
        return this.authenticated.username || '';
    }

    public get isImpersonated(): boolean {
        return this.authenticated.impersonated;
    }

    public get isRequireFullProfiled(): boolean {
        return this.authenticated.require_full_profile || false;
    }

    public get hasRequireCompletedProfile(): boolean {
        return this.authenticated.require_completed_profile || false;
    }

    public get userName(): string {
        const lastName = this.authenticated.last_name || '';
        const firstName = this.authenticated.first_name || '';

        return `${firstName} ${lastName}`;
    }

    public get getToken(): string {
        return this.token;
    }

    public get version(): string {
        return this.keepAliveVersion;
    }

    public get isShowRequestForm(): boolean {
        return this.showRequestForm;
    }

    public get canShowAskForSubscriptionDialog(): boolean {
        return this.showAskForSubscriptionDialog;
    }

    public get accessControlList(): Record<string, boolean> {
        return this.acl;
    }

    public get canChangePassword(): boolean {
        return this.acl['users.password'] || false;
    }

    public get canAssignAgent(): boolean {
        return this.acl['agents.assign'] || false;
    }

    public get canDeleteAgent(): boolean {
        return this.acl['agents.delete'] || false;
    }

    public get canExportAgent(): boolean {
        return this.acl['agents.export'] || false;
    }

    public get canAgentSettings(): boolean {
        return this.acl['agents.settings'] || false;
    }

    public get canUpdateAgent(): boolean {
        return this.acl['agents.updateSettings'] || false;
    }

    public get canSeeAgentLogs(): boolean {
        return this.acl['agents.history'] || false;
    }

    public get canSeeAgentEvents(): boolean {
        return this.acl['agentEvents.list'] || false;
    }

    public get canAddJob(): boolean {
        return this.acl['jobs.add'] || false;
    }

    public get canApproveJob(): boolean {
        return this.acl['jobs.approval'] || false;
    }

    public get canUpdateJob(): boolean {
        return this.acl['jobs.updateApproval'] || false;
    }

    public get canAddCustomer(): boolean {
        return this.acl['customers.add'] || false;
    }

    public get canSeeCustomers(): boolean {
        return this.acl['customers.list'] || false;
    }

    public get canSeeUnassigned(): boolean {
        return this.acl['customers.viewUnassigned'] || false;
    }

    public get canSeeCustomerLogs(): boolean {
        return this.acl['customers.history'] || false;
    }

    public get canSeeCustomerTypes(): boolean {
        return this.acl['customers.types'] || false;
    }

    public get canToggleFavoriteCustomer(): boolean {
        return this.acl['customers.toggleFavorite'] || false;
    }

    public get canToggleFavoriteSolution(): boolean {
        return this.acl['solutions.toggleFavorite'] || false;
    }

    public get canToggleFavoriteAgent(): boolean {
        return this.acl['agents.toggleFavorite'] || false;
    }

    public get canAddUser(): boolean {
        return this.acl['users.add'] || false;
    }

    public get canSeeUsers(): boolean {
        return this.acl['users.list'] || false;
    }

    public get canSeeFilterPassword(): boolean {
        return this.acl['users.passwordFilter'] || false;
    }

    public get canAddSolution(): boolean {
        return this.acl['solutions.add'] || false;
    }

    public get canEditSolution(): boolean {
        return this.acl['solutions.edit'] || false;
    }

    public get canDeleteSolution(): boolean {
        return this.acl['solutions.delete'];
    }

    public get canSeeSolutions(): boolean {
        return this.acl['solutions.list'];
    }

    public get canSeeSolutionLogs(): boolean {
        return this.acl['solutions.history'];
    }

    public get canSeeExplore(): boolean {
        return this.acl['stats.dashboard'] || false;
    }

    public get canSeeSystemEvents(): boolean {
        return this.acl['systemEvents.list'] || false;
    }

    public get canSeeReverseTunnelActivity(): boolean {
        return this.acl['jobLogs.list'] || false;
    }

    public get canAcknowledgeAgentEvents(): boolean {
        return this.acl['agentEvents.acknowledge'] || false;
    }

    public get canAddProfileSecret(): boolean {
        return this.acl['profile.secret'] || false;
    }

    public get canManageReleases(): boolean {
        return this.acl['releases.manage'] || false;
    }

    public get canSeeReleases(): boolean {
        return this.acl['releases.view'] || false;
    }

    public get canUseGlobalSearch(): boolean {
        return this.acl.search || false;
    }

    @Mutation
    public setLoggedIn(isLoggedIn: boolean): void {
        this.loggedIn = isLoggedIn;
    }

    @Mutation
    public setCustomerLogs(value: boolean): void {
        this.acl['customers.history'] = value;
    }

    @Mutation
    public setSolutionLogs(value: boolean): void {
        this.acl['solutions.history'] = value;
    }

    @Mutation
    public setAgentLogs(value: boolean): void {
        this.acl['agents.history'] = value;
    }

    @Mutation
    public setUserActivity(value: boolean): void {
        this.acl['users.activity'] = value;
    }

    @Mutation
    public setUserLogs(value: boolean): void {
        this.acl['users.history'] = value;
    }

    @Mutation
    public setVersion(version: string): void {
        this.keepAliveVersion = version;
    }

    @Mutation
    public setToken(token: string): void {
        this.token = token;
    }

    @Mutation
    public setUser(user: ProfileInterface): void {
        this.userData = user;
    }

    @Mutation
    public setUpdateSecret(secret: string) {
        this.userData.secret = secret;
    }

    @Mutation
    public clearToken() {
        this.token = '';
    }

    @Mutation
    public setShowRequestForm(request: boolean): void {
        this.showRequestForm = request;
    }

    @Mutation
    public setShowAskForSubscriptionDialog(request: boolean): void {
        this.showAskForSubscriptionDialog = request;
    }

    @Mutation
    public setRequireCompletedProfile(request: boolean): void {
        this.authenticated.require_completed_profile = request;
    }

    @Mutation
    public setAuthenticatedUser(user: AuthenticatedInterface): void {
        this.authenticated = user;
    }

    @Mutation
    public setAccessControlList(acl: Record<string, boolean> = {}) {
        this.acl = acl;
    }

    @Mutation
    public mergeAccessControlList(acl: Record<string, boolean> = {}) {
        this.acl = Object.assign(this.acl, acl);
    }

    @Mutation
    public setUpdateAuthenticatedUser(user: ProfileInterface) {
        this.authenticated = {
            ...this.authenticated,
            first_name: user.firstName,
            last_name: user.lastName,
            username: user.email,
            require_completed_profile: false
        };
    }

    @Action
    public async logout(): Promise<any> {
        return (new AuthApi()).logout().then(() => {
            this.context.commit('setLoggedIn', false);
            this.context.commit('setUser', {});
            this.context.commit('setAccessControlList', {});
            this.context.commit('setShowAskForSubscriptionDialog', false);

            localStorage.setItem('logout-event', `logout${Math.random()}`);
        });
    }

    @Action
    public async getCurrentUser(): Promise<any> {
        return new ProfileApi().getProfile().then(response => {
            const user = response.data.result;

            this.context.commit('setUser', {
                firstName: user.first_name,
                lastName: user.last_name || '',
                id: user.id || '',
                secret: user.secret || '',
                email: user.username,
                customer: user.customer,
                company: user.company || '',
                country: user.country || '',
                jobRole: user.job_role || '',
                preferredLanguage: user.preferred_language || '',
                state: user.state || '',
                stateText: user.state_text || '',
            });
        });
    }

    @Action
    public async setNewSecretCurrentUser(): Promise<any> {
        return new ProfileApi().createNewSecret().then(response => {
            const { secret } = response.data.result;

            this.context.commit('setUpdateSecret', secret);
        });
    }

    @Action
    public async clearSecretCurrentUser(): Promise<any> {
        return new ProfileApi().clearSecret().then(response => {
            const { secret } = response.data.result;

            this.context.commit('setUpdateSecret', secret);
        });
    }

    @Action
    public async login(): Promise<any> {
        this.context.commit('setLoggedIn', true);
    }

    @Action({ rawError: true })
    public async changePassword(password: string): Promise<any> {
        return new ProfileApi().changePassword(password).then(response => {
            this.context.commit('setLoggedIn', false);
            return response;
        });
    }

    @Action({ rawError: true })
    public async updateUser(user: ProfileInterface): Promise<any> {
        return new Promise((resolve, reject) => {
            (new ProfileApi()).updateProfile(user).then(response => {
                this.context.commit('setUser', user);
                this.context.commit('setUpdateAuthenticatedUser', user);
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    @Action({ rawError: true })
    public async getAuthenticatedUser(): Promise<any> {
        try {
            const response = await (new ConfigApi()).keepAlive();
            const { user, acl, ask_for_subscriptions } = response.data.result;

            acl['customers.performanceTopMenu'] = user.customer_id !== 1 && acl['customers.performanceTopMenu'];
            this.context.commit('setAccessControlList', acl);
            this.context.commit('setAuthenticatedUser', user);

            if (ask_for_subscriptions) this.context.commit('setShowAskForSubscriptionDialog', ask_for_subscriptions);
        } catch (e) {
            console.error(e);
        }
    }
}

export default AuthModule;
