
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import AuthApi from '@/services/api/AuthApi';

const theme = namespace('themeModule');

@Component
export default class GetTokenDialog extends Vue {
    @theme.Getter
    public showTooltips!: boolean;

    public dialog: boolean = false;
    public confirmDialog: boolean = false;
    public token: string = '';
    public isSending: boolean = false;

    public setShow(): void {
        this.dialog = true;
        this.getToken();
    }

    public close(): void {
        this.dialog = false;
        this.confirmDialog = false;
        this.token = '';
    }

    public copyText(text: string): void {
        navigator.clipboard.writeText(text);
    }

    public getToken(): void {
        this.isSending = true;
        (new AuthApi()).getAssociationToken().then(response => {
            this.token = response.data.result;
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.isSending = false;
        });
    }

    public createNewToken(): void {
        this.confirmDialog = false;
        this.isSending = true;
        (new AuthApi()).createAssociationToken().then(response => {
            this.token = response.data.result;
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.isSending = false;
        });
    }
}
