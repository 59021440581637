import moment from 'moment-timezone';

const momentDuration = require('moment-duration-format');

momentDuration(moment);

export default class TimeUtil {
    public static interval: number = 0;
    public static attempt: number = 0;

    public static getTime(time: string): string {
        const date = moment.utc(time);
        return `${date.format('YYYY-MM-DD HH:mm z')} (${moment(date.tz(moment.tz.guess())).fromNow()})`;
    }

    public static getTimeWithSeconds(time: string): string {
        const date = moment.utc(time);
        return `${date.format('YYYY-MM-DD HH:mm:ss z')} (${moment(date.tz(moment.tz.guess())).fromNow()})`;
    }

    public static getCurrentDateTime(format: string = 'YYYY-MM-DD HH:mm') {
        return moment().format(format);
    }

    public static getCurrentUtcDateTime(format: string = 'YYYY-MM-DD HH:mm') {
        return moment().utc().format(format);
    }

    public static getTimeUserTz(time: string): string {
        return moment.utc(time).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z');
    }

    public static getTimeUserTzWithSeconds(time: string): string {
        return moment.utc(time).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss z');
    }

    public static getTimeUnix(time: string, format: string = 'YYYY-MM-DD'): number {
        return moment.tz(time, format, moment.tz.guess()).unix();
    }

    public static getTimeUTCUnix(time: string): number {
        return moment.utc(time).unix();
    }

    public static getTimeUnixWithMinutes(time: string): number {
        return moment.tz(time, 'MM/DD/YYYY hh:mm A', moment.tz.guess()).unix();
    }

    public static getDifferenceTimeMinutes(time: string): number {
        const start = moment(new Date());
        const end = moment.utc(time);

        return start.diff(end, 'minutes');
    }

    public static getDifferenceTimeHours(time: string): number {
        const start = moment(new Date());
        const end = moment.utc(time);

        return start.diff(end, 'hours');
    }

    public static getDifferenceTimePeriodsHours(startUnix: number, endUnix: number): number {
        const start = moment.unix(startUnix);
        const end = moment.unix(endUnix);

        return end.diff(start, 'hours');
    }

    public static getDifferenceTimePeriodsDays(startUnix: number, endUnix: number): number {
        const start = moment.unix(startUnix);
        const end = moment.unix(endUnix);

        return end.diff(start, 'days');
    }

    public static getTimePeriodFromDays(days: number, endDateToday: boolean = false): [string, string] {
        let end = moment(new Date());
        const start = moment(end).add(1 - days, 'days');

        if (Number(days) === 2) { end = moment(end).add(-1, 'days'); } // yesterday

        const beginDate = moment(start).format('YYYY-MM-DD');
        const endDate = moment(end).add(endDateToday ? 0 : 1, 'days').format('YYYY-MM-DD');

        return [beginDate, endDate];
    }

    public static transformDateRangeSeparator(period: Array<any>, isUTCFormat: boolean = false): string {
        if (isUTCFormat) {
            return `${this.getTimeUTCUnix(period[0])}|${TimeUtil.getTimeUTCUnix(period[1])}`;
        }
        return `${this.getTimeUnix(period[0])}|${this.getTimeUnix(period[1])}`;
    }

    public static transformDateRangePeriodInUnixTime(period: number): any {
        const endTimeUnix = moment.utc(new Date()).unix();
        const startTimeUnix = moment.unix(endTimeUnix).subtract(period, 'days').unix();

        return [startTimeUnix, endTimeUnix];
    }

    public static getChangedDateByDays(time: string, days: number): string {
        const date = moment(time).add(days, 'days');

        return date.format('YYYY-MM-DD');
    }

    public static getChangedTimeByMinutes(time: string, minutes: number): string {
        const date = moment(time, 'HH:mm').add(minutes, 'minutes');

        return date.format('HH:mm');
    }

    public static transformSecondsDuration(
        seconds: number,
        format?: string | undefined,
        settings?: any
    ): any {
        return (moment as any).duration(seconds, 'seconds').format(format, settings);
    }

    public static transformDateStringFormat(
        date: string,
        format: string = 'YYYYMMDD',
        newFormat: string = 'YYYY-MM-DD',
    ): any {
        return moment(date, format).format(newFormat);
    }

    public static isAfterTime(time: string, format: string = 'YYYY-MM-DD HH:mm') {
        const targetUtcTime = moment.utc(time, format);
        const currentUtcTime = moment.utc(new Date(), format);

        return targetUtcTime.isAfter(currentUtcTime);
    }

    public static isBeforeTime(time: string, format: string = 'YYYY-MM-DD HH:mm') {
        const targetUtcTime = moment.utc(time, format);
        const currentUtcTime = moment.utc(new Date(), format);

        return targetUtcTime.isBefore(currentUtcTime);
    }

    public static getUserTimeZoneAbbr(): string {
        return moment().tz(moment.tz.guess()).format('z');
    }
}
