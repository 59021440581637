export default {
    email: (value: string) => {
        // eslint-disable-next-line
        const pattern = new RegExp(''
            + /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/.source
            + /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source);
        return pattern.test(value) || 'Invalid e-mail';
    },
    phone: (value: string) => {
        if (value) {
            const pattern = new RegExp(/^[+]?(?!-)([(][0-9]{1,3}[)])?([-\s]?[0-9])+$/);
            return pattern.test(value) || 'Invalid phone number';
        }
        return true;
    },
    password: (value: string) => value.trim().length >= 8 || 'At least 8 characters',
    requiredRoles: (roles: string[]) => !!roles.length || 'Required field',
    maxLen: (value: string) => value.length <= 25 || 'The field should not be longer than 25 characters',
};
