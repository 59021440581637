import Vue from 'vue';
import store from '@/store';
import ErrorHandlerApi from '@/services/api/ErrorHandlerApi';

/**
 *
 * @param err error trace
 * @param vm component in which error occured
 * @param info Vue specific error information such as lifecycle hooks, events etc.
 */

Vue.config.errorHandler = (err: Error, vm, info: string) => {
    const version: string = store.getters['siteConfigModule/getUIVersion'];
    const message = err.stack || `${err.name}: ${err.message}`;

    console.error(err);

    (new ErrorHandlerApi()).sendError({
        message,
        component: `[${vm.$options.name}]: <${vm.$el.nodeName} class='${vm.$el.className}'>`,
        info: `[${info}]: ${err.message}`,
        page: `[${vm.$route.name}]: ${vm.$route.path}`,
        _fe_version: version,
    });
};
