import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class ProductApi {
    protected backendURL: string = store.getters['siteConfigModule/getBackend'];

    public getProductClasses(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/products/classes`);
    }

    public getProductFamilies(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/products/families`);
    }

    public getProductModels(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/products/models`);
    }

    public getProductSeverities(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/misc/log-levels`);
    }

    public getJobStatuses(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/misc/job-statuses`);
    }

    public getProductCustomerTypes(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/customers/types`);
    }
}
