import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import MenuInterface from '@/interfaces/MenuInterface';
import store from '@/store';

@Module({
    namespaced: true,
    name: 'menuModule',
})
class MenuModule extends VuexModule {
    private items: MenuInterface[] = [];
    private currentRouteName: string = '';

    public get getCurrentRouteName(): string {
        return this.currentRouteName;
    }

    @Mutation
    public setCurrentRouteName(value: string): void {
        this.currentRouteName = value;
    }

    @Mutation
    public setMenu(menu: MenuInterface[]): void {
        this.items = menu;
    }

    @Mutation
    public resetMenu(): void {
        this.items = [];
    }

    @Mutation
    public initMenu(menu: MenuInterface[]): void {
        const acl = store.getters['authModule/accessControlList'];
        const route = store.getters['menuModule/getCurrentRouteName'];
        menu.forEach((item: MenuInterface) => {
            if (item.roles) {
                const isVisible: boolean = item.roles.some((role: string) => acl[role]);
                Object.assign(item, { isVisible });
            }

            if (item.items) {
                item.items.forEach((subItem: MenuInterface) => {
                    if (subItem.roles) {
                        const isVisible: boolean = subItem.roles.some((role: string) => acl[role]);
                        Object.assign(subItem, { isVisible });
                    }
                    if (subItem.link?.name === route) {
                        Object.assign(item, { isCollapsed: false });
                    }
                });
            }
        });

        this.items = menu;
    }

    @Mutation
    public setMenuRestrictions(): void {
        const acl = store.getters['authModule/accessControlList'];
        this.items.forEach((item: MenuInterface) => {
            if (item.roles) {
                const isVisible: boolean = item.roles.some((role: string) => acl[role]);
                Object.assign(item, { isVisible });
            }
        });
    }

    public get menu(): MenuInterface[] {
        return this.items;
    }
}

export default MenuModule;
