import moment from 'moment-timezone';

export default {
    required: (value: string) => !!value || 'Required field',
    requiredCondition: (condition: boolean) => condition || 'Required field',
    requiredWithCondition: (value: string | number, condition: boolean) => (!!value || !condition) || 'Required field',
    isNotEmpty: (value: string) => (value && value.trim().length > 0) || 'Field should not be empty',
    numbers: (value: string) => /^[0-9]\d*$/.test(value) || 'Field should contain an integer',
    requiredNumbers: (value: string) => (!!value && /^[0-9]\d*$/.test(value)) || 'Required field (should contain an integer)',
    requiredInteger: (value: string|number|null) => Number.isInteger(value) || 'Required field',
    alphanumeric: (value: string) => /^[a-zA-Z0-9]*$/.test(value) || 'Only alphanumeric characters allowed',
    serialnumber: (value: string) => /^[a-zA-Z0-9-]*$/.test(value) || 'Only alpha-numeric and dash characters allowed',
    ip: (value: string) => {
        const ipPattern = new RegExp(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/);

        return (value === '' || ipPattern.test(value)) || 'IP address format required';
    },
    email: (value: string) => {
        const pattern = new RegExp(`${
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/.source
        }${/((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source}`);
        return pattern.test(value) || 'Invalid e-mail';
    },
    quantumEmail: (value: string, role: string) => {
        const quantumRolesArr = ['admin', 'csm', 'engineering', 'product-manager', 'support', 'sales'];

        return (quantumRolesArr.includes(role) ? value.toLowerCase().includes('@quantum.com') || value.includes('@quantum.com') : true)
            || 'For the selected role, the email address must end with @quantum.com';
    },
    number: (value: string) => {
        const numberPattern = new RegExp(/^[0-9]\d*$/);

        return (value === '' || numberPattern.test(value)) || 'Only positive integer numbers allowed';
    },
    numberOrEmpty: (value: string) => {
        const numberPattern = new RegExp(/^-?[0-9]\d*$/);

        return (!value || numberPattern.test(value)) || 'Only integer numbers allowed';
    },
    numberRangeOrEmpty: (value: string, min: number = 0, max: number = 999) => {
        const numberPattern = new RegExp(/^[0-9]+(\.[0-9]+)?$/);

        if (!value) {
            return true;
        }

        if (!numberPattern.test(value)) {
            return 'Only positive numbers allowed';
        }

        const numericValue = parseInt(value, 10);
        if (numericValue < min || numericValue > max) {
            return `Value must be between ${min} and ${max}`;
        }

        return true;
    },
    isHigher: (value1: number, value2: number) => (value2 === 0 || value1 < value2) || `Field should be higher to ${value1}`,
    isHigherOrEqual: (value1: number, number: number) => (value1 === 0 || value1 >= number) || `Field should be higher or equal to ${number}`,
    multipleSelect: (values: []) => values?.length > 0 || 'Required field',
    whitespace: (value: string) => value.trim() === value || 'Field should not contain leading or trailing whitespace',
    emptyOrMinLength: (value: string, len: number = 2) => (!value || value.length >= len) || `Field should not be shorter than ${len} characters`,
    emptyOrMaxLength: (value: string, len: number = 16) => value.length <= len || `Field should not be longer than ${len} characters`,
    minLength: (value: string, len: number = 2) => (value && value.length >= len) || `Field should not be shorter than ${len} characters`,
    maxLength: (value: string, len: number = 16) => (value && value.length <= len) || `Field should not be longer than ${len} characters`,
    isColon: (value: string) => !(value.includes(':')) || 'Field should not contain colon',
    isRegionCode: (value: string) => {
        const numberPattern = new RegExp(/^([a-zA-Z]{2})$/);

        return (!value || numberPattern.test(value)) || 'Field should contain two letters';
    },
    isURL: (value: string) => {
        // eslint-disable-next-line
        const urlPattern = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);

        return (value === '' || value === null || urlPattern.test(value)) || 'URL address format required';
    },
    isValidDate: (value: string) => {
        const datePattern = /^(199[0-9]|2[0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        const isValidDate = moment(value, 'YYYY-MM-DD', true).isValid();

        return (value === '' || value === null || (datePattern.test(value) && isValidDate)) || 'Invalid date format';
    },
    maxFileSize: (value: any, maxSize: number = 20000000) => (value && value.size <= maxSize) || `File size should be less than ${maxSize / 1000000}MB!`
};
