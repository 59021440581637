import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';
import ProfileInterface from '@/interfaces/ProfileInterface';
import ShareLinkInterface from '@/interfaces/ShareLinkInterface';

export default class ProfileApi {
    protected backend: string = store.getters['siteConfigModule/getBackend'];

    public getProfile(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/profile/account`);
    }

    public updateProfile(user: ProfileInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/profile/account`, {
            first_name: user.firstName,
            last_name: user.lastName,
            username: user.email,
            company: user.company,
            country: user.country,
            state: user.state,
            job_role: user.jobRole,
            preferred_language: user.preferredLanguage,
        });
    }

    public changePassword(password: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/profile/password`, {
            password,
            password_confirmation: password
        });
    }

    public createNewSecret(): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/profile/secret`);
    }

    public clearSecret(): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/profile/secret`, { clear: 'yes' });
    }

    public shareLinkToEmail(params: ShareLinkInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/profile/share-link`, params);
    }
}
