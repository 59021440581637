import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store';
import HttpMethods from '@/constants/HttpMethods';
import ApiCodeStatuses from '@/constants/StatusCodes';
import GlobalNotificationHandler from '@/services/GlobalNotificationHandler';

const ignoreTokenUrls = ['keep-alive', 'confirm'];
const ignoreSnackbarUrls = ['authenticate', 'logout', 'keep-alive', 'error-report'];

export const ERR_CANCELED_CODE = 'ERR_CANCELED';

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

const showSnackBar = (config: AxiosRequestConfig): boolean => ignoreSnackbarUrls.every(url => config && config.url && !config.url.includes(url));

const needTokenAsQueryParam = (config: AxiosRequestConfig) => (
    config.method === HttpMethods.DELETE && ignoreTokenUrls.every(url => config.url && !config.url.includes(url))
);

api.interceptors.request.use(
    config => {
        if (config.url && config.url.includes('error-report')) return config;
        const token: string = store.getters['authModule/getToken'];
        const version: string = store.getters['siteConfigModule/getUIVersion'];

        if (needTokenAsQueryParam(config)) {
            if (!config.params) {
                // eslint-disable-next-line
                config.params = {};
            }
            // eslint-disable-next-line
            config.params._token = token;
        } else if (config.data && !config.data._token) {
            // eslint-disable-next-line
            config.data._token = token;
        }

        if (!config.params) {
            // eslint-disable-next-line
            config.params = {};
        }

        // eslint-disable-next-line
        config.params._fe_version = version;

        return config;
    },
    error => Promise.reject(error),
);

api.interceptors.response.use(async response => {
    if (response.status === ApiCodeStatuses.Unauthorized) {
        // TODO HANDLE REFRESH TOKEN AND UNAUTH
        return Promise.reject(response);
    }

    const { config } = response;

    if ((config.method === HttpMethods.POST || config.method === HttpMethods.DELETE) && showSnackBar(config)) {
        GlobalNotificationHandler.handleSuccessNotification(response);
    }

    return response;
}, error => {
    if (error.code === ERR_CANCELED_CODE) {
        return Promise.reject(error);
    }

    if (error.response && showSnackBar(error.response.config)) {
        GlobalNotificationHandler.handleErrorNotification(error.response);
    }

    if (!error.response) {
        GlobalNotificationHandler.handleLimitedServerAccess();
    }

    return Promise.reject(error.response);
});

export default api;
