var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-inline"},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.showTooltips},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.isMenuItem && !_vm.isGeneralDialog),expression:"!isMenuItem && !isGeneralDialog"}],staticClass:"ml-1 pr-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},on),[_vm._v("mdi-form-textbox-password")])]}}])},[_c('span',[_vm._v("Change Password")])]),(_vm.isMenuItem && !_vm.isGeneralDialog)?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-key-change")]),_vm._v("Change Password")],1)],1):_vm._e(),_c('v-dialog',{attrs:{"transition":"scale-transition","width":"500"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"accent rounded-lg"},[_c('v-card-title',{staticClass:"custom-title text-body-2 mb-2"},[_c('v-icon',[_vm._v("mdi-key-change")]),_vm._v("Change Password")],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"compact-input",attrs:{"label":"Password","filled":"","dense":"","disabled":_vm.isSending,"tabindex":"1","prepend-icon":"mdi-key","append-icon":_vm.newPasswordShow ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.newPasswordShow ? 'text' : 'password',"rules":[
                                        _vm.commonRules.required,
                                        _vm.passwordRules.minLength(8),
                                        _vm.passwordRules.upperCase(_vm.newPassword),
                                        _vm.passwordRules.lowerCase(_vm.newPassword),
                                        _vm.passwordRules.digit(_vm.newPassword),
                                        _vm.passwordRules.specialCharacter,
                                    ]},on:{"click:append":function($event){_vm.newPasswordShow = !_vm.newPasswordShow}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"compact-input",attrs:{"label":"Confirm Password","filled":"","dense":"","tabindex":"2","prepend-icon":"mdi-key","append-icon":_vm.confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.confirmPasswordShow ? 'text' : 'password',"disabled":_vm.isSending,"rules":[
                                    _vm.commonRules.required,
                                    _vm.passwordRules.confirm(_vm.confirmPassword, _vm.newPassword),
                                ]},on:{"click:append":function($event){_vm.confirmPasswordShow = !_vm.confirmPasswordShow}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end pr-7 pb-7"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid || _vm.isSending},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }