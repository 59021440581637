/**
 * Adapter over axios http client
 *
 * It encapsulates logic in the class to give wrapped interface for our application
 * You can easily replace it with different http client
 */

import { AxiosPromise } from 'axios';
import HttpConfigInterface from '@/interfaces/HttpConfigInterface';
import api from '../services/api';

export default class HttpAdapter {
    /**
     * @param {string} url
     * @param {HttpConfigInterface} config
     * @returns {AxiosPromise}
     */
    public static get(
        url: string,
        config: HttpConfigInterface = {},
    ): AxiosPromise {
        const httpClientOptions: any = {};

        return api.get(url, { ...config, ...httpClientOptions });
    }

    /**
     * @param {string} url
     * @param {HttpConfigInterface} config
     * @param {any} data
     * @returns {AxiosPromise}
     */
    public static post(
        url: string,
        data: any = {},
        config: HttpConfigInterface = {},
    ): AxiosPromise {
        const httpClientOptions: any = {};

        return api.post(url, data, { ...config, ...httpClientOptions });
    }

    /**
     * @param {string} url
     * @param {HttpConfigInterface} config
     * @param {any} data
     * @returns {AxiosPromise}
     */
    public static put(
        url: string,
        data: any,
        config: HttpConfigInterface = {},
    ): AxiosPromise {
        const httpClientOptions: any = {};

        return api.put(url, data, { ...config, ...httpClientOptions });
    }

    /**
     * @param {string} url
     * @param {HttpConfigInterface} config
     * @param {any} data
     * @returns {AxiosPromise}
     */
    public static patch(
        url: string,
        config: HttpConfigInterface = {},
        data: any,
    ): AxiosPromise {
        const httpClientOptions: any = {};

        return api.patch(url, data, { ...config, ...httpClientOptions });
    }

    /**
     * @param {string} url
     * @param {HttpConfigInterface} config
     * @returns {void}
     */
    public static delete(
        url: string,
        config: HttpConfigInterface = {},
    ): AxiosPromise {
        const httpClientOptions: any = {};

        return api.delete(`${url}`, { ...config, ...httpClientOptions });
    }
}
