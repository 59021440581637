import BaseSelectInterface from '@/interfaces/BaseSelectInterface';
import SortUtil from '@/utils/SortUtil';

export default class UserFilterTransformer {
    public static transformRoles(types: { [x: string]: any; }): BaseSelectInterface[] {
        const transformed: BaseSelectInterface[] = [];

        Object.keys(types).forEach(key => {
            transformed.push({
                value: key,
                text: types[key]
            });
        });

        return SortUtil.sortObjectByText(transformed);
    }
}
