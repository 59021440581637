import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';
import ConfigApi from '@/services/api/ConfigApi';

export default class AuthApi {
    protected backend: string = store.getters['siteConfigModule/getBackend'];

    public checkAuthenticationMethods(username: string): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/authenticate/method`, {
            params: {
                username
            }
        });
    }

    public login(parameters: { username: string; password: string }): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/authenticate`, {
            username: parameters.username,
            password: parameters.password,
        });
    }

    public logout(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/logout`, {});
    }

    public accountRequest(user: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/account-request`, user);
    }

    public forgotPassword(username: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/forgot-password`, { username });
    }

    public validateResetPasswordLink(
        id: string | (string | null)[],
        token: string | (string | null)[]
    ): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/reset-password`,
            {
                params: {
                    token,
                    id
                }
            }
        );
    }

    public resetPassword(id: string, token: string, password: string) {
        return new ConfigApi().keepAlive().then(response => {
            HttpAdapter.post(
                `${this.backend}/web-api/reset-password`,
                {
                    token,
                    id,
                    password,
                    password_confirmation: password,
                    _token: response.data.result.token,
                }
            );
        });
    }

    public getAssociationToken(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/profile/association-token`);
    }

    public createAssociationToken(): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/profile/association-token`);
    }
}
