import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import BaseApiClient from '@/services/api/BaseApiClient';

export default class LogsApi extends BaseApiClient {
    protected type: string = 'jobs/logs';

    public getUsersAdminLogList(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/jobs/logs/users`);
    }

    public getOpenTunnelJobs(params: any): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/jobs/logs`,
            {
                params: { ...params }
            }
        );
    }

    public getJobLogDetails(params: any): any {
        return HttpAdapter.get(`${this.backend}/web-api/jobs/logs/${params.jobId}`);
    }

    public getJobUploads(jobId: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/jobs/${jobId}/uploads`);
    }
}
