
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UserRules from '@/rules/UserRules';
import commonRules from '@/rules/CommonRules';
import ProfileInterface from '@/interfaces/ProfileInterface';
import UsersApi from '@/services/api/UsersApi';
import BaseSelectInterface from '@/interfaces/BaseSelectInterface';
import ProductFilterTransformer from '@/transformers/ProductFilterTransformer';

const theme = namespace('themeModule');
const auth = namespace('authModule');

@Component
export default class MyProfileDialog extends Vue {
    @theme.Getter
    public showTooltips!: boolean;
    @auth.Getter
    public canAddProfileSecret!: boolean;
    @auth.Getter
    public user!: ProfileInterface;
    @auth.Action
    public getCurrentUser!: () => Promise<ProfileInterface>;
    @auth.Action
    public updateUser!: (user: ProfileInterface) => Promise<any>;
    @auth.Action
    public setNewSecretCurrentUser!: () => Promise<any>;
    @auth.Action
    public clearSecretCurrentUser!: () => Promise<any>;
    @auth.Getter
    public isRequireFullProfiled!: boolean;
    @auth.Getter
    public hasRequireCompletedProfile!: boolean;
    @auth.Getter
    public isImpersonated!: boolean;
    @auth.Mutation
    public setRequireCompletedProfile!: (request: boolean) => void;

    public dialog: boolean = false;
    public valid = true;
    public rules = { ...commonRules, ...UserRules };
    public isSending: boolean = true;
    public secretShow: boolean = false;
    public countriesList: Array<string> = [];
    public jobRolesList: Array<string> = [];
    public languagesList: Array<string> = [];
    public statesList: Array<BaseSelectInterface> = [];
    public userJobTitle: string = '';
    public userPreferredLanguage: string = '';
    public constStrOther: string = 'Other';
    public constStrUsa: string = 'United States';

    @Watch('hasRequireCompletedProfile', { deep: true })
    public hasRequireCompletedProfileChanged(): void {
        if (this.hasRequireCompletedProfile && !this.dialog) {
            this.setShow();
        }
    }

    public async setShow(): Promise<void> {
        this.dialog = true;

        try {
            await this.getCurrentUser();

            if (this.isRequireFullProfiled) {
                const response = await (new UsersApi().getDemographic());
                const { countries, job_roles, languages, states } = response.data.result;

                this.countriesList = countries;
                this.jobRolesList = [...job_roles.sort(), { divider: true }, this.constStrOther];
                this.languagesList = [...languages, { divider: true }, this.constStrOther];
                this.statesList = ProductFilterTransformer.transformAndSortObjectToArray(states, true);

                if (this.user.preferredLanguage && !languages.includes(this.user.preferredLanguage)) {
                    this.userPreferredLanguage = this.user.preferredLanguage;
                    this.user.preferredLanguage = this.constStrOther;
                }

                if (this.user.jobRole && !job_roles.includes(this.user.jobRole)) {
                    this.userJobTitle = this.user.jobRole;
                    this.user.jobRole = this.constStrOther;
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            (this.$refs.form as any).resetValidation();
            this.isSending = false;
        }
    }

    public copyText(text: string): void {
        navigator.clipboard.writeText(text);
    }

    public createNewSecret(): void {
        this.isSending = true;

        this.setNewSecretCurrentUser().finally(() => {
            this.isSending = false;
        });
    }

    public clearSecret(): void {
        this.isSending = true;

        this.clearSecretCurrentUser().finally(() => {
            this.isSending = false;
        });
    }

    public changeCountrySelect(value: any): void {
        if (value !== this.constStrUsa) delete this.user.state;
    }

    public changeJobRoleSelect(value: any): void {
        if (value !== this.constStrOther) this.userJobTitle = '';
    }

    public changePreferredLanguageSelect(value: any): void {
        if (value !== this.constStrOther) this.userPreferredLanguage = '';
    }

    public submit(): void {
        this.isSending = true;

        const copyUser = { ...this.user };

        if (this.userPreferredLanguage) copyUser.preferredLanguage = this.userPreferredLanguage;
        if (this.userJobTitle) copyUser.jobRole = this.userJobTitle;

        this.updateUser(copyUser).then(() => {
            this.close();
        }).catch(error => {
            this.isSending = false;
            console.error(error);
        });
    }

    public close(): void {
        this.dialog = false;
        this.isSending = true;
        this.secretShow = false;

        if (this.hasRequireCompletedProfile) this.setRequireCompletedProfile(false);

        (this.$refs.form as any).resetValidation();
    }

    public get isDisableStateInput(): boolean {
        return !(this.user.country === this.constStrUsa);
    }

    public get isDisableJobTitleInput(): boolean {
        return !(this.user.jobRole === this.constStrOther);
    }

    public get isDisablePreferredLanguageInput(): boolean {
        return !(this.user.preferredLanguage === this.constStrOther);
    }

    public get isHideCancelButton(): boolean {
        return this.hasRequireCompletedProfile && !this.isImpersonated;
    }

    public get numberOfColumns(): number {
        return this.isRequireFullProfiled ? 6 : 12;
    }

    public get dialogWidth(): number {
        return this.isRequireFullProfiled ? 950 : 750;
    }
}
