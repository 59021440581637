import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import BaseApiClient from '@/services/api/BaseApiClient';
import { CustomerFilterInterface } from '@/interfaces/CustomerInterface';
import ChartParametersInterface from '@/interfaces/ChartParametersInterface';

export default class CustomersApi extends BaseApiClient {
    protected type: string = 'customers';

    public getCustomers(filters?: CustomerFilterInterface) {
        return HttpAdapter.get(`${this.backend}/web-api/customers`, { params: { ...filters } });
    }

    public getAllCustomersList() {
        return HttpAdapter.get(`${this.backend}/web-api/customers/list`);
    }

    public getCustomer(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/customers/${id}`);
    }

    public createCustomer(name: string, type: string, disable_global_alert: boolean = true): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/add`, {
            name,
            type,
            disable_global_alert
        });
    }

    public updateCustomer(id: string, name: string, type: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${id}/edit`, {
            name,
            type,
        });
    }

    public deleteCustomer(id: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${id}/delete`, {});
    }

    public toggleFavoriteCustomer(id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${id}/toggle-favorite`, {});
    }

    public getCurrentPartners(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/partners/${id}`);
    }

    public getCustomerSubscriptions(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/customers/${id}/subscriptions`);
    }

    public updateCustomerSubscriptions(id: number, severities?: Array<number> | undefined, severities_digest?: Array<number> | undefined): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${id}/subscriptions`, { severities, severities_digest });
    }

    public getAvailablePartners(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/partners/${id}/add`);
    }

    public addPartner(id: number, partner_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/partners/${id}/add`, {
            partner_id
        });
    }

    public removePartner(id: number, partner_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/partners/${id}/remove/${partner_id}`, {});
    }

    public getAvailablePartnersFrom(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/partners/${id}/add-from`);
    }

    public addPartnerFrom(id: number, partner_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/partners/${id}/add-from`, {
            partner_id
        });
    }

    public removePartnerFrom(id: number, partner_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/partners/${id}/remove-from/${partner_id}`, {});
    }

    public getUnhealthyAgents(params: any): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/customers/${params.id}/unhealthy-agents`,
            {
                params: { ...params }
            }
        );
    }

    public getLTOTypes(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/customers/${id}/scalar-charts/lto-types`);
    }

    public getMediaReadWritten(customerId: number, params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${customerId}/scalar-charts/media-read-written`, { ...params });
    }

    public getMediaUsage(customerId: number, type: string): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/customers/${customerId}/${type}/media-usage`);
    }

    public getCustomerAgentEvents(customerId: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/agent-events/customer/${customerId}/quick-view`);
    }

    public getChartData(params: ChartParametersInterface, customerId: number, chart: string, family: string): AxiosPromise {
        if (params.controller) {
            // eslint-disable-next-line
            params.node = params.controller;
        }

        return HttpAdapter.get(
            `${this.backend}/web-api/customers/${customerId}/${family}/chart-data/${chart}`,
            {
                params,
            },
        );
    }

    public getPerformanceDashboardList(customerId: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/customers/${customerId}/dashboards`);
    }

    public setPerformanceDashboard(customerId: number, params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${customerId}/dashboards`, { ...params });
    }

    public getAvailableChartsPerformanceDashboard(customerId: number, name: string): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/customers/${customerId}/dashboards/${name}/available-charts`);
    }

    public setActivePerformanceDashboard(customerId: number, name: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${customerId}/dashboards/last/${name}`);
    }

    public deletePerformanceDashboard(customerId: number, name: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/customers/${customerId}/dashboards/${name}/delete`);
    }
}
