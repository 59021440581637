
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

const theme = namespace('themeModule');

@Component
export default class DebounceInput extends Vue {
    @theme.Getter
    public showTooltips!: boolean;

    @Prop({ default: 'Search' }) readonly label!: string;
    public debounceTimer: number = 400; // default timeout, ms
    @Prop({ type: Boolean, required: false, default: false })
    public readonly autofocus?: boolean;
    @Prop({ type: Boolean, required: false, default: false })
    public readonly loading?: boolean;
    @Prop({ type: Number, required: false, default: -1 })
    public readonly result?: number;
    @Prop({ type: String, required: false, default: '' })
    public readonly textTooltip?: string;
    @Prop({ type: Boolean, required: false, default: false })
    public readonly isGlobalSearch?: boolean;
    @Prop({ type: Number, required: false, default: 0 })
    public readonly nudgeTop?: number;
    public timeoutID: number = 0;
    public search: string = '';

    public created() {
        this.search = this.$attrs.value ? this.$attrs.value : '';
    }

    public beforeDestroy() {
        clearTimeout(this.timeoutID);
    }

    @Emit('input')
    returnValue(): string {
        return this.search;
    }

    public submitInput(): void {
        clearTimeout(this.timeoutID);

        this.returnValue();
    }

    public handleInput(): void {
        clearTimeout(this.timeoutID);

        this.timeoutID = setTimeout(() => {
            this.returnValue();
        }, this.debounceTimer);
    }

    public handleEnter(): void {
        if (this.isGlobalSearch) this.$emit('key-pressed');
    }

    public getHint(): string {
        if (!this.isGlobalSearch) return '';

        if (this.loading) return 'Processing...';

        if (Number(this.result) > 0) return `${this.result} item(s) found`;

        if (Number(this.result) === 0) return 'No matches for your search';

        return 'Start typing to search something...';
    }
}
