
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';

const theme = namespace('themeModule');

@Component
export default class ClipboardCopy extends Vue {
    @Prop({ required: true, type: String })
    public value!: string;
    @Prop({ required: false, type: String, default: () => '' })
    public classes!: string;
    @theme.Getter
    public showTooltips!: boolean;

    public isHovered: boolean = false;

    public copy(): void {
        navigator.clipboard.writeText(this.value);
    }
}
