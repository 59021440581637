
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HeartGauge from '@quantum/common-vue-components/src/components/health/Gauge/HeartGauge.vue';
import HealthUtil from '@/utils/HealthUtil';

@Component({
    components: {
        HeartGauge,
    }
})
export default class HeartChart extends Vue {
    @Prop({ required: true, type: Number, default: 0 })
    public percentage!: number;
    @Prop({ required: false, type: Number, default: 1 })
    public size!: number;
    public hcInstance: any = Highcharts;
    public color!: string;

    @Watch('percentage')
    public updateColor(): void {
        if (this.percentage <= 0) {
            this.color = '#5a5a5a';
        } else {
            this.color = HealthUtil.getHealthColor(this.percentage);
        }
    }

    public created(): void {
        this.updateColor();
    }
}
