
import { Vue, Component } from 'vue-property-decorator';
import UsersApi from '@/services/api/UsersApi';
import { DEFAULT_START_PAGE } from '@/router';

@Component
export default class ImpersonationHeader extends Vue {
    public async endImpersonation(): Promise<void> {
        await (new UsersApi().endImpersonateUser().then(response => {
            if (response.data.success) {
                window.location.href = DEFAULT_START_PAGE.path;
            }
        }));
    }
}
