import Vue from 'vue';
import Vuex from 'vuex';
import MenuModule from '@/store/menu';
import MessageModule from '@/store/message';
import AuthModule from '@/store/auth';
import ThemeModule from '@/store/theme';
import GlobalState from '@/interfaces/StoreInterface';
import ProductModule from '@/store/product';
import SiteConfigModule from '@/store/SiteConfig';
import NotificationModule from '@/store/notification';
import UserModule from '@/store/user';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        tooltips: true, reload: {}
    } as GlobalState,

    getters: {
        getReload: state => state.reload
    },

    mutations: {
        setReload: (state: GlobalState, val: any) => {
            state.reload[val.store] = val.value;
        },
    },

    actions: {},

    modules: {
        menuModule: MenuModule,
        messageModule: MessageModule,
        authModule: AuthModule,
        siteConfigModule: SiteConfigModule,
        themeModule: ThemeModule,
        notificationModule: NotificationModule,
        productModule: ProductModule,
        userModule: UserModule,
    },
});

export default store;
