import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import store from '@/store';
import ChartParametersInterface from '@/interfaces/ChartParametersInterface';
import ChartPerformanceParametersInterface from '@/interfaces/ChartPerformanceParametersInterface';
import CapacityListParametersInterface from '@/interfaces/CapacityListParametersInterface';
import ChartCapacityParametersInterface from '@/interfaces/ChartCapacityParametersInterface';

export default class ExploreApi {
    protected backend: string = store.getters['siteConfigModule/getBackend'];

    public getChartData(chart: string, params?: ChartParametersInterface, path: string = 'explore'): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/${path}/${chart}`,
            {
                params,
            },
        );
    }

    public getProductFamilies(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/active-agents-table`, {});
    }

    public getPerformanceMetrics(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/performance-metrics`);
    }

    public getPerformanceChartData(params?: ChartPerformanceParametersInterface): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/explore/performance-chart`,
            {
                params,
            },
        );
    }

    public getCapacityListData(params?: CapacityListParametersInterface): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/explore/capacity-list`,
            {
                params,
            },
        );
    }

    public getCapacityChartData(params?: ChartCapacityParametersInterface): AxiosPromise {
        return HttpAdapter.get(
            `${this.backend}/web-api/explore/capacity-chart`,
            {
                params,
            },
        );
    }

    public getCapacityGroups(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/capacity-groups`);
    }

    public getRegionItems(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/contract-regions`);
    }

    public getContractCountriesItems(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/contract-countries`);
    }

    public getStatusItems(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/bundles-status`);
    }

    public getTransferStatusItems(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/bundles-transfer-status`);
    }

    public getCategoryItems(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/bundles-categories`);
    }

    public getJobUsers(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/explore/jobs-users`);
    }
}
