import TimeUtil from '@/utils/TimeUtil';

export default class LocalStorageUtil {
    public static localStorageSaveFilterKey: string = 'filters_v2';

    public static isHideIpAddresses(): boolean {
        const themeValue = localStorage.getItem('isHideIpAddresses');

        return (themeValue === null) ? false : JSON.parse(themeValue);
    }

    public static isDarkTheme(): boolean {
        const themeValue = localStorage.getItem('isDarkTheme');

        return (themeValue === null) ? true : JSON.parse(themeValue);
    }

    public static isShowTooltips(): boolean {
        const themeValue = localStorage.getItem('isShowTooltips');

        return (themeValue === null) ? true : JSON.parse(themeValue);
    }

    public static isMenuMinimized(): boolean {
        const themeValue = localStorage.getItem('isMenuMinimized');

        return (themeValue === null) ? false : JSON.parse(themeValue);
    }

    public static getItemsByKey(key: string): any {
        return localStorage.getItem(this.localStorageSaveFilterKey)
        && (JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey)))[key]
            ? JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey))[key]
            : null;
    }

    public static getItemByKey(key: string, name: string): any {
        return localStorage.getItem(this.localStorageSaveFilterKey)
        && (JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey)))[key]
        && JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey))[key][name]
            ? JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey))[key][name]
            : { };
    }

    public static setItemByKey(key: string, name: string, item: unknown): void {
        const obj = localStorage.getItem(this.localStorageSaveFilterKey)
        && (JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey)))[key]
            ? { ...(JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey)))[key], [name]: item }
            : { [name]: item };

        this.setLocalStorageItem(key, obj);
    }

    public static setLocalStorageItem(key: string, item: unknown): void {
        localStorage.setItem(
            this.localStorageSaveFilterKey,
            JSON.stringify({
                ...JSON.parse(<string>localStorage.getItem(this.localStorageSaveFilterKey)),
                [key]: item
            })
        );
    }

    public static setStorageItem(key: string, value: string): void {
        localStorage.setItem(
            key,
            value,
        );
    }

    public static getStorageItem(key: string): string | null {
        return JSON.parse(<string>localStorage.getItem(key));
    }

    public static setUpdateDateRangeFilter(
        saveKeyFilter: string,
        numberRange: number,
        filterTypeKey: string = '',
    ): void {
        const isUTCFormat = saveKeyFilter === 'explore-performance';

        let obj: any = {};
        const objFilters = JSON.parse(localStorage.getItem(this.localStorageSaveFilterKey) || '{}');
        const dateRange = TimeUtil.transformDateRangeSeparator(TimeUtil.getTimePeriodFromDays(numberRange), isUTCFormat);

        if (filterTypeKey) {
            obj = {
                ...objFilters,
                [saveKeyFilter]: {
                    ...objFilters[saveKeyFilter],
                    dateParams: {
                        [filterTypeKey]: {
                            dateRange,
                            numberRange
                        }
                    }
                }
            };
            if (objFilters[saveKeyFilter] && objFilters[saveKeyFilter].dateParams) {
                obj[saveKeyFilter].dateParams = {
                    ...objFilters[saveKeyFilter].dateParams,
                    [filterTypeKey]: {
                        dateRange,
                        numberRange
                    }
                };
            }
        } else {
            obj = { ...objFilters,
                [saveKeyFilter]: { ...objFilters[saveKeyFilter],
                    dateParams: { dateRange, numberRange } } };
        }


        localStorage.setItem(
            this.localStorageSaveFilterKey,
            JSON.stringify(obj)
        );
    }

    public static saveDateRangeParams(saveKeyFilter: any, filterTypeKey: any, dateParams?: any): void {
        const objFilters = JSON.parse(localStorage.getItem(LocalStorageUtil.localStorageSaveFilterKey) || '{}');
        let newObjFilters = {};

        if (dateParams) {
            newObjFilters = {
                ...objFilters,
                [saveKeyFilter]: {
                    ...objFilters[saveKeyFilter],
                    dateParams: {
                        ...objFilters[saveKeyFilter].dateParams,
                        [filterTypeKey]: dateParams
                    }
                }
            };
        } else {
            const objDateParams = objFilters[saveKeyFilter].dateParams;
            delete objDateParams[filterTypeKey];

            newObjFilters = { ...objFilters,
                [saveKeyFilter]: Object.keys(objDateParams).length ? {
                    ...objFilters[saveKeyFilter],
                    dateParams: objDateParams
                } : {
                    filters: objFilters[saveKeyFilter].filters,
                    options: objFilters[saveKeyFilter].options,
                } };
        }

        localStorage.setItem(
            LocalStorageUtil.localStorageSaveFilterKey,
            JSON.stringify(newObjFilters)
        );
    }

    public static getDateRangeParams(saveKeyFilter: string, filterTypeKey: string): any {
        const objJsonFilters = JSON.parse(localStorage.getItem(LocalStorageUtil.localStorageSaveFilterKey) || '{}');
        const dateRangeParamsObj: any = {};

        if (localStorage.getItem(LocalStorageUtil.localStorageSaveFilterKey)
            && objJsonFilters[saveKeyFilter]
            && objJsonFilters[saveKeyFilter].dateParams
        ) {
            const storageDateParams = objJsonFilters[saveKeyFilter].dateParams;

            Object.keys(storageDateParams).forEach((key: string) => {
                const numberRangeDateParams = Number(storageDateParams[key].numberRange);

                if (storageDateParams[key].numberRange > 0) {
                    dateRangeParamsObj[key] = {
                        numberRange: numberRangeDateParams,
                        dateRange: TimeUtil.transformDateRangeSeparator(
                            TimeUtil.getTimePeriodFromDays(numberRangeDateParams)
                        ),
                    };

                    LocalStorageUtil.setUpdateDateRangeFilter(saveKeyFilter, numberRangeDateParams, filterTypeKey);
                } else if (storageDateParams[key].numberRange === 0) {
                    dateRangeParamsObj[key] = {
                        numberRange: numberRangeDateParams,
                        dateRange: storageDateParams[key].dateRange,
                    };
                }
            });
        }

        return dateRangeParamsObj;
    }
}
