import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';
import ErrorHandlerInterface from '@/interfaces/ErrorHandlerInterface';

export default class ErrorHandlerApi {
    protected backend: string = store.getters['siteConfigModule/getBackend'];

    public sendError(error: ErrorHandlerInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/error-report`, {
            ...error
        });
    }
}
