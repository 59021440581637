import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import store from '@/store';

export default class FilesUploadApi {
    protected backendURL: string = store.getters['siteConfigModule/getBackend'];

    public uploadLicenseReport(payload: FormData): AxiosPromise {
        const config: any = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return HttpAdapter.post(`${this.backendURL}/web-api/agents/stornext/license-report/file`, payload, config);
    }
}
