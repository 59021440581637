
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import NotificationInterface from '@/interfaces/NotificationInterface';

const notification = namespace('notificationModule');

@Component
export default class AlertNotification extends Vue {
    public show: boolean = false;
    @notification.Getter
    public alertNotification!: NotificationInterface;
    @notification.Mutation
    public clearAlertNotification!: () => void;

    @Watch('alertNotification')
    public notificationChanged(): void {
        this.show = Boolean(this.alertNotification.msg);
    }

    public close(): void {
        this.clearAlertNotification();
    }
}
