import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class ConfigApi {
    protected url: string = store.getters['siteConfigModule/getBackend'];

    public keepAlive(): AxiosPromise {
        return HttpAdapter.get(`${this.url}/web-api/keep-alive`);
    }
}
