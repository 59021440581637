
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import NotificationInterface from '@/interfaces/NotificationInterface';

const notification = namespace('notificationModule');

@Component
export default class ToastNotification extends Vue {
    public show: boolean = false;
    @notification.Getter
    public getSnackBarNotification!: NotificationInterface;

    @Watch('getSnackBarNotification')
    public notificationChanged(): void {
        this.show = true;
    }

    public getTimeout(): number {
        return this.getSnackBarNotification.type === 'error' ? 10000 : 3000;
    }
}
