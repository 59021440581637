import HttpAdapter from '@/adapters/HttpAdapter';
import store from '@/store';
import { ItemGlobalSearchInterface } from '@/interfaces/GlobalSearchInterface';

const itemTypeIconMap: { [key : string] : string } = {
    organization: 'mdi-office-building',
    solution: 'mdi-lightbulb',
    node: 'mdi-server-network'
};

const itemTypeActionMap: { [key : string] : string } = {
    organization: 'customer-details',
    solution: 'solution-details',
    node: 'node-details'
};

export default class GlobalSearchApi {
    protected backend: string = store.getters['siteConfigModule/getBackend'];

    public getGlobalSearch(search: string) {
        return HttpAdapter.get(`${this.backend}/web-api/search?query=${search}`).then(response => ({
            data: response.data.result.map((item: ItemGlobalSearchInterface) => ({
                ...item,
                icon: itemTypeIconMap[item.category],
                action: itemTypeActionMap[item.category],
            })),
        }));
    }
}
