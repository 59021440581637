export default class SortUtil {
    public static sortObjectByText(obj: any[], sortByABC: boolean = false, key: string = 'text'): any {
        return obj.sort((a, b) => {
            let textA = a[key];
            let textB = b[key];
            if (sortByABC) {
                textA = textA.toLowerCase();
                textB = textB.toLowerCase();
            }

            if (textA < textB) return -1;

            if (textA > textB) return 1;

            return 0;
        });
    }

    public static compareValues = (key: string, order: number = 1) => {
        const orderType = (order === 1) ? 'acs' : 'desc';
        let varA: any;
        let varB: any;

        return (a: any, b: any) => {
            if (a[key] && b[key]) {
                varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
                varB = (typeof a[key] === 'string') ? b[key].toUpperCase() : b[key];
            }

            let comparison = 0;

            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            if (orderType === 'desc') {
                return comparison * -1;
            }

            return comparison;
        };
    };
}
