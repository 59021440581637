import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';
import BaseApiClient from '@/services/api/BaseApiClient';

export default class ExploreProductApi extends BaseApiClient {
    protected type: string = 'releases/trees';

    public getProductReleaseTrees(): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/releases/trees`);
    }

    public getProductReleaseTree(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/releases/trees/${id}`);
    }

    public createProductReleaseTree(product_family_id: number, product_model_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/new`, {
            product_family_id,
            product_model_id
        });
    }

    public updateProductReleaseTree(id: number, product_family_id: number, product_model_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${id}`, {
            product_family_id,
            product_model_id
        });
    }

    public deleteProductReleaseTree(id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${id}/delete`, {});
    }

    public getProductReleasBranches(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/releases/trees/${id}/branches`);
    }

    public createProductReleaseBranch(id: number, name: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${id}/branches/new`, {
            name
        });
    }

    public updateProductReleaseBranch(id: number, branch_id: number, name: string): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${id}/branches/${branch_id}`, {
            name
        });
    }

    public deleteProductReleaseBranch(id: number, branch_id: number): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${id}/branches/${branch_id}/delete`, {});
    }

    public getProductReleaseVersions(params: any) {
        return HttpAdapter.get(
            `${this.backend}/web-api/releases/trees/${params.id}/versions`,
            {
                params: { ...params }
            }
        ).then(response => ({
            data: response.data.result.data.map((item: any) => ({
                ...item,
            })),
            total: response.data.result.total
        }));
    }

    public getProductReleaseBranchesList(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/releases/trees/${id}/branches/list`);
    }

    public getProductReleaseVersionsList(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backend}/web-api/releases/trees/${id}/versions/list`);
    }

    public deleteProductReleaseVersion(params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${params.treeID}/versions/${params.versionID}/delete`, {});
    }

    public productReleaseVersionMarkEosl(params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${params.treeID}/versions/${params.versionID}/eosl`, {});
    }

    public productReleaseVersionMarkSupported(params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${params.treeID}/versions/${params.versionID}/supported`, {});
    }

    public createProductReleaseVersion(params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${params.treeID}/versions/new`, {
            release_branch_id: params.release_branch_id,
            version: params.version,
            status: params.status,
            released_at: params.released_at,
            upgrade_message: params.upgrade_message,
            target_version: params.target_version,
            documentation_url: params.documentation_url,
            release_notes_url: params.release_notes_url,
        });
    }

    public updateProductReleaseVersion(params: any): AxiosPromise {
        return HttpAdapter.post(`${this.backend}/web-api/releases/trees/${params.treeID}/versions/${params.versionID}`, {
            release_branch_id: params.release_branch_id,
            version: params.version,
            status: params.status,
            released_at: params.released_at,
            upgrade_message: params.upgrade_message,
            target_version: params.target_version,
            documentation_url: params.documentation_url,
            release_notes_url: params.release_notes_url,
        });
    }
}
