import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import UserApi from '@/services/api/UsersApi';
import BaseSelectInterface from '@/interfaces/BaseSelectInterface';
import UserFilterTransformer from '@/transformers/UserFilterTransformer';

@Module({
    namespaced: true,
    name: 'userModule',
})
class UserModule extends VuexModule {
    public userRoles: any = [] as unknown;

    public get roles(): BaseSelectInterface[] {
        return UserFilterTransformer.transformRoles(this.userRoles);
    }

    @MutationAction({ mutate: ['userRoles'] })
    public async resetUserRoleItems(): Promise<any> {
        const userRoles: any[] = [];

        return { userRoles };
    }

    @MutationAction({ mutate: ['userRoles'] })
    public async getUserRoleItems(): Promise<any> {
        let userRoles: any[] = [];

        try {
            const response = await new UserApi().getAllRoles();
            userRoles = response.data.result.roles;
        } catch (e) {
            console.error(e);
        }

        return { userRoles };
    }
}

export default UserModule;
